import React from 'react'
import CIcon from '@coreui/icons-react'

const _navMainSaller = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Customers',
    to: '/customers',
    icon: 'cil-calculator',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Products',
    route: '/Products',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Products',
        to: '/products',
      },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Stock',
    route: '/Stock',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Add Stock',
        to: '/add-stock',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Current Stock',
        to: '/current-stock',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Stock History',
        to: '/stock-history',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Stock Requests',
        to: '/stock-requests',
      },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Sale',
    route: '/Sale',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Sale Product',
        to: '/sale-product',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Sale History',
      //   to: '/sale-history',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Sale Reports',
        to: '/sale-reports',
      },
    ]
  },
]

export default _navMainSaller
