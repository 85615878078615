/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React from "react";
import { useParams } from "react-router-dom";
import Updatestudent from "./Updatestudent";
import { ChakraProvider, Checkbox } from "@chakra-ui/react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SchoolBlock from "./School/SchoolBlock";
import ExportApis from "src/Constants/Apis/ExportApis";

import { Flex, Heading, List, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useDrop } from "react-dnd";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useEffect } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { date } from "yup/lib/locale";
import { connect } from "react-redux";
// validation start from here for yup
const validationSchema = Yup.object().shape({
  MobileNo: Yup.string()
    // regexr.com/6anqd
    .min(10)
    .max(10)
    .matches(/[7-9]{1}[0-9 ]{4}[0-9 ]{4}[0-9]{1}/, {
      message: "Invalid  number",
      excludeEmptyString: false,
    })
    .required("Mobile number is required"),
  Dob: Yup.string().required("Dob is required"),
  Gender: Yup.string().required("Gender is required"),
  PhysicalDisability: Yup.string().required("Physical disability is required"),
  email: Yup.string().required("Email is required"),

});

const StudentListForm = (props) => {
  const paths = props?.location?.pathname.split("/")
  let UentityId = props.location.pathname;
  // const UentityId = "5120010005";
  const StudentUentityId = paths[2];
  // const StudentUentityId = parseInt(props);
  console.log("ss", StudentUentityId, paths[3]);
  const [data, setData] = useState(null);
  const [secmodal, setSecmodal] = useState(false);
  const [user, setUser] = useState(false);
  const [datafi, setDatafi] = useState({});
  const [blog, setBlog] = useState({})
  const [chkValue, setChkValue] = useState(false);

  const [player, setPlayer] = useState([]);
  const [dataa, setdataa] = useState({});
  const [PhysicaldDisability, setPhysicaldDisability] = useState(false);
  const [genderm, setGenderm] = useState(false);
  const [on, setOn] = useState(false);
  const [laston, setLastOn] = useState(false);
  const [studentcon, setstudentcon] = useState(true);
  const [startDate, setStartDate] = useState("03/03/1977");
  const [endDate, setEndDate] = useState("03/03/1997");
  const [Chakrad, setChakrad] = useState(false);
  const [Studentdata, setstudentdata] = useState([]);
  // const [Logindata, setLogindata] = useState(
  //   JSON.parse(localStorage.getItem("user"))
  // );
  const [physicalDisabilityDocumentimg, setphysicalDisabilityDocumentimg] =
    useState();
  const [filtertow, setfiltertow] = useState({});
  const [Visiblt, setVisiblt] = useState(false);
  const [proproLL, setproproLL] = useState();
  const [Pregnancydeliverydateinput, setPregnancydeliverydateinput] =
    useState(false);
  const [genderp, setGenderp] = useState(false);
  const [rollNumberInput, setRollNumberInput] = useState(props.RollNo);

  const [school, setSchool] = useState([]);

  const [team, setTeam] = useState([]);
  const [image, setimage] = useState("");
  const [edit, setedit] = useState(false);
  const [StartValue, setStartValue] = useState(null);
  // const [EndValue, setEndValue] = useState(
  //   new Date().getFullYear(),
  //   new Date().getMonth() + 1,
  //   15
  // );
  useEffect(() => {
    GetStudentList();

    // handleState();
    // handleBlog();
  }, []);
  // console.log("log", Logindata?.uEntityId);

  const handeleimage = (e) => {
    setimage(e.target.files[0]);
  };
  // useEffect(() => {
  //   handlesingleStudentdata();
  // }, [1000]);
  const GetStudentList = () => {
    console.log("Check " + props?.userDetail, paths[3]);
    ExportApis.Postdatastudentlist(props?.userDetail, 0,paths[3]).then((resp) => {
      // ExportApis.Postdatastudentlist(29).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;

        console.log(Data)
        let ro = resp.data.filter((val) =>
          val.rollNo ? val.rollNo == StudentUentityId : "error in post student"

        );
        if (ro.length > 0) setUser(ro[0]);
        console.log("Student id ........===>", ro);
        setstudentdata(Data);
      }
    });
  };
  const PhysicalDisDocument = (e) => {
    console.log(e);
    //setphysicalDisabilityDocumentimg(e.fileList[0]);
    //  console.log("pppppp",PhysicalDisabilityDocument)
  };
  //console.log("user?.uentityId)", user?.uentityId);
  const PostStudentform = async (values) => {
    let data = new FormData();
    data.append("UentityId", user?.uentityId);
    data.append("email", values.email);
    data.append("ExpectedDeliveryDate", values.ExpectedDeliveryDate);
    data.append("PhysicalDisabilityDocument", image);
    data.append("MobileNo", values.MobileNo);
    data.append("VisibilityImpaired", values.VisibilityImpaired);
    data.append("DisabilityMobility", values.DisabilityMobility);
    data.append("DisabilityPercentage", values.DisabilityPercentage);
    data.append("MarriageStatus", values.MarriageStatus);
    data.append("Gender", values.Gender);
    data.append("PregnancyStatus", values.PregnancyStatus);
    data.append("PhysicalDisability", values.PhysicalDisability);
    let date = new Date(values.Dob);
    data.append(
      "Dob",
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    );
    console.log("ddd", data)

    await ExportApis.Postdatas(data)
      .then((resp) => {
        if (resp.ok) {
          // setFormvale(values);
          handlesingleStudentdata();
          setSecmodal(false);
          setVisiblt(true);
          // setVisible(false);
          // console.log("form value data show*****", values);
        } else {
          alert("error PostStudentform" + resp.data);
        }
      })

      .catch((err) => console.log(err));
  };

  const handlesingleStudentdata = () => {
    console.log("UID",user?.uentityId)
    ExportApis.StudentDetails(user?.uentityId).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        // this.setState({ datafi: resp.data });
        setDatafi(resp.data);
        // console.log("singleData List........", this.state.datafi);
      }
    });

    // fetch(
    //   `http://demo.swapdeal.io//api/College/StudentDetails` + user?.uentityId
    // ).then((result) => {
    //   result.json().then((resp) => {
    //     // eslint-disable-next-line eqeqeq
    //     console.log("student data", resp);
    //     setDatafi(resp);
    //   });
    // });
  };

  // const handleState = (id) => {
  //   fetch(`http://demo.swapdeal.io//api/College/Schoollist25`).then(
  //     (result) => {
  //       result.json().then((resp) => {
  //         // eslint-disable-next-line eqeqeq
  //         //  console.log("schoolname", resp);
  //         setSchool(resp);
  //       });
  //     }
  //   );
  // };
  // console.log("3456ert", Logindata?.uEntityId);
  // const handleBlog = () => {
  //   fetch(
  //     `http://demo.swapdeal.io/api/Location/Block/${Logindata?.uEntityId}`
  //   ).then((result) => {
  //     result.json().then((resp) => {
  //       setBlog(resp);
  //       // console.log("blogg==>>>>", resp);
  //     });
  //   });
  // };

  const isConfirm = () => {
    ExportApis.IsConfirmCollege(user.uentityId, 1)
      .then((resp) => {
        if (resp.ok) {
          // setFormvale(values);
          // alert("posted");
          // setOn(true);
          props.history.goBack();
           // navigate(-1);
          setVisiblt(false);
          // setVisible(false);
          //  console.log("form value data show*****");
        } else {
          alert("err");
        }
      })

      .catch((err) => console.log(err));
  };
  console.log("rollno", StudentUentityId);
  return (
    <>
      {studentcon ? (
        <>
          <div className="shadow p-2 mb-5 bg-white ">
            <div className="container">
              <center style={{ color: "#27AE60" }}>
                {user ? (
                  <>
                    {" "}
                    <div className="row">
                      <div className="col-4 text-left">Roll Number</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left">{user.rollNo}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">Name</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left">{user.name}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">Father Name</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left">{user.fatherName}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">Class Name</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left"> {user.className}</div>
                    </div>
                    <div className="row">
                      <div className="col-8 text-left"><h3>Instruction:Please Check all Deatils Before confrim</h3></div>
                      {/* <div className="col-1 text-left">:</div> */}
                      {/* <div className="col-4 text-left"> {user.className}</div> */}
                      {/* <div className="col-4 text-left">Please Check all Deatils Before confrim</div> */}
                    </div>
                    <div className="form-check text-left">
                      <input className="form-check-input" type="checkbox" value="enable" id="flexCheckDefault" onClick={() => setChkValue(!chkValue)} />
                      <label className="form-check-label" for="flexCheckDefault">
                        Yes I am agree
                      </label>
                    </div>
                    <div className="form-group">





                    </div>
                    <br />

                  </>
                ) : (
                  <>loding.....</>
                )}
                <button
                  className="btn btn-success btn-block"
                  disabled={chkValue != "" ? false : true}

                  onClick={() => {
                    setstudentcon(false);
                    setSecmodal(true);
                  }}
                >
                  Confirm
                </button>
              </center>
              <br />
            </div>
          </div>
        </>
      ) : null}

      <Modal
        show={secmodal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>Student Details</Modal.Header>
        <Modal.Body>
          <div className="shadow p-2 mb-5 bg-white ">
            <div className="container">
              <center style={{ color: "#27AE60" }}>
                {user ? (
                  <>
                    {" "}
                    <div className="row">
                      <div className="col-4 text-left">Roll Number</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left">{user.rollNo}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">Name</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left">{user.name}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">Father Name</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left">{user.fatherName}</div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">Class Name</div>
                      <div className="col-1 text-left">:</div>
                      <div className="col-4 text-left"> {user.className}</div>
                    </div>
                    <br />
                  </>
                ) : (
                  <>loding.....</>
                )}
              </center>
              <br />
              <center style={{ color: "#27AE60" }}>
                <h3 style={{ fontWeight: "bold" }}>Please Fill Form</h3>
              </center>
              <br />
            </div>
          </div>
          <Container>
            <Formik
              initialValues={{
                Gender: edit ? datafi.genderId : "",
                ExpectedDeliveryDate: edit ? datafi.expectedDeliveryDate : "",
                PhysicalDisabilityDocument: edit ? image : "",
                PregnancyStatus: edit ? datafi.isPregnant : "",
                VisibilityImpaired: edit ? datafi.visibilityImpaired : "",
                DisabilityMobility: edit ? datafi.disabilityMobility : "",
                DisabilityPercentage: edit ? datafi.disabilityPercentage : "",
                PhysicalDisability: edit ? datafi.physicalDisability : "",
                Dob: edit ? new Date(datafi.dob) : new Date("1970/03/03"),
                email: edit ? datafi.emailId : "",
                MobileNo: edit ? datafi.mobileNo : "",
                MarriageStatus: edit ? datafi.marriageStatus : "",
              }}

              onSubmit={(values) => {
                // console.log(values);
                PostStudentform(values);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <div className="messages" />
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="form-control"
                      placeholder="example@gmail.com  "
                    />
                    {errors.email && touched.email ? (
                      <div style={{ color: "red" }}>{errors.email}</div>
                    ) : null}
                  </div>
                  <label>Mobile No</label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="MobileNo"
                      id="MobileNo"
                      onChange={(e) =>
                        JSON.stringify(e.target.value).length <= 12
                          ? handleChange(e)
                          : null
                      }
                      onBlur={handleBlur}
                      value={values.MobileNo}
                      className="form-control"
                      placeholder="Enter Mobile No"
                    />
                    {errors.MobileNo && touched.MobileNo ? (
                      <div style={{ color: "red" }}>{errors.MobileNo}</div>
                    ) : null}
                    <div className="help-block with-errors" />
                  </div>
                  <label>Date Of Birth</label>
                  <div className="form-group">
                    <DatePicker
                      className="form-control"
                      name="Dob"

                      selected={values.Dob}
                      onChange={(date) => {
                        setFieldValue("Dob", date);
                      }}

                      minDate={new Date("1977-03-03")}
                      showYearDropdown
                      scrollableYearDropdown
                    />

                    {/* <DatePicker selected={StartValue} onChange={(date) => setStartValue(date)} /> */}
                    {/* <input
                      type="Date"
                      name="Dob"
                      id="Dob"
                      onChange={(e) => {
                        handleChange(e);
                        // handeldob(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.Dob}
                      className="form-control"
                      placeholder="Dob"
                    /> */}
                    {errors.Dob && touched.Dob ? (
                      <div style={{ color: "red" }}>{errors.Dob}</div>
                    ) : null}
                    <div className="help-block with-errors" />
                  </div>
                  <label> Physical Disability </label>
                  <div>
                    <select
                      name="PhysicalDisability"
                      id="PhysicalDisability"
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.PhysicalDisability}
                      className="form-control mb-2"
                      aria-label="Default select example"
                      onChange={(e) => {
                        handleChange(e);
                        e.target.value == 0 || e.target.value == "null"
                          ? setPhysicaldDisability(false)
                          : setPhysicaldDisability(true);
                      }}
                    >
                      <option className="form-control" value="null" selected>
                        Open This Select Menu
                      </option>
                      <option className="form-control" value={1}>
                        Yes
                      </option>
                      <option className="form-control" value={0}>
                        No
                      </option>
                    </select>
                    {errors.PhysicalDisability && touched.PhysicalDisability ? (
                      <div style={{ color: "red" }}>
                        {errors.PhysicalDisability}
                      </div>
                    ) : null}
                    {PhysicaldDisability ? (
                      <>
                        <label>Disability Percentage</label>
                        <div >
                          <select

                            name="DisabilityPercentage"
                            id="DisabilityPercentage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DisabilityPercentage}
                            className="form-control mb-2"
                            aria-label="Default select example"
                          >
                            <option className="form-control" value={0} selected>
                              Open this select Percentage
                            </option>
                            <option className="form-control" value={10}>
                              10
                            </option>
                            <option className="form-control" value={20}>
                              20
                            </option>
                            <option className="form-control" value={40}>
                              40
                            </option>
                            <option className="form-control" value={70}>
                              70
                            </option>
                            <option className="form-control" value={99}>
                              100
                            </option>
                          </select>
                        </div>
                        <label>Disability Mobility </label>
                        <div >
                          <select
                            style={{ width: "100%", paddingBlock: "7px" }}
                            name="DisabilityMobility"
                            id="DisabilityMobility"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DisabilityMobility}
                            className="form-control mb-2"
                            aria-label="Default select example"
                          >
                            <option className="form-control" selected>
                              Open this select Visibility
                            </option>
                            <option className="form-control" value={1}>
                              Yes
                            </option>
                            <option className="form-control" value={0}>
                              No
                            </option>
                          </select>
                        </div>
                        <label>Visibility Impaired</label>
                        <div >
                          <select
                            style={{ width: "100%", paddingBlock: "7px" }}
                            name="VisibilityImpaired"
                            id="VisibilityImpaired"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.VisibilityImpaired}
                            className="form-control mb-2"
                            aria-label="Default select example"
                          >
                            <option className="form-control" selected>
                              Open this select Visibility
                            </option>
                            <option className="form-control" value={1}>
                              Yes
                            </option>
                            <option className="form-control" value={0}>
                              No
                            </option>
                          </select>
                        </div>

                        <label for="formFile" class="form-label">
                          Physical Disability Document
                        </label>
                        <div >
                          <input
                            className="form-control mb-2"
                            name="PhysicalDisabilityDocument"
                            type="file"
                            id="formFile"
                            onChange={(e) => {
                              handeleimage(e);
                              handleChange(e);
                            }}
                          />
                        </div>
                      </>
                    ) : null}

                  </div>
                  <div>
                    <label>Gender</label>
                  </div>
                  <div className="form-check form-check-inline mb-3" >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Gender"
                      checked={values.Gender == 1}
                      value={1}
                      onChange={(e) => {
                        handleChange(e);
                        e.target.value == 1 || e.target.value == 3
                          ? setGenderm(false) ??
                          setGenderp(false) ??
                          setPregnancydeliverydateinput(false)
                          : setGenderm(true);
                      }}
                      onBlur={handleBlur}
                    />
                    <label
                      style={{ paddingRight: "7px" }}
                      className="form-check-label"
                      for="inlineRadio1" >
                      MALE
                    </label>
                  </div>

                  <div className="form-check form-check-inline mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Gender"
                      checked={values.Gender == 2}
                      value={2}
                      onChange={(e) => {
                        handleChange(e);
                        e.target.value == 1 || e.target.value == 3
                          ? setGenderm(false) ??
                          setGenderp(false) ??
                          setPregnancydeliverydateinput(false)
                          : setGenderm(true);
                      }}
                      onBlur={handleBlur}
                    />
                    <label
                      style={{ paddingRight: "7px" }}
                      className="form-check-label"
                      htmlfor="inlineRadio2"
                    >
                      FEMALE
                    </label>
                  </div>
                  <div className="form-check form-check-inline mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Gender"
                      value={3}
                      onChange={(e) => {
                        handleChange(e);
                        e.target.value == 1 || e.target.value == 3
                          ? setGenderm(false) ??
                          setGenderp(false) ??
                          setPregnancydeliverydateinput(false)
                          : setGenderm(true);
                      }}
                      onBlur={handleBlur}
                    />
                    <label className="form-check-label" htmlfor="inlineRadio3">
                      OTHER
                    </label>
                  </div>

                  {errors.Gender && touched.Gender ? (
                    <div style={{ color: "red" }}>{errors.Gender}</div>
                  ) : null}



                  {genderm ? (
                    <>
                      <div>
                        <label>Are You Married ?</label>
                      </div>

                      <div className="form-group" style={{ display: "inline" }}>
                        <div style={{ marginBottom: "30px" }}>
                          <div
                            className="form-check form-check-inline"
                            style={{ display: "inline" }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"

                              name="MarriageStatus"
                              value={1}
                              onChange={(e) => {
                                handleChange(e);
                                e.target.value == 0
                                  ? setGenderp(false) ??
                                  setPregnancydeliverydateinput(false)
                                  : setGenderp(true);
                              }}
                              onBlur={handleBlur}
                            />

                            <label
                              style={{ paddingRight: "7px" }}
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"

                              name="MarriageStatus"
                              value={0}
                              onChange={(e) => {
                                handleChange(e);
                                e.target.value == 0
                                  ? setGenderp(false) ??
                                  setPregnancydeliverydateinput(false)
                                  : setGenderp({ genderp: true });
                              }}
                              onBlur={handleBlur}
                            />
                            <label
                              style={{ paddingRight: "7px" }}
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {genderp ? (
                    <>
                      <div>
                        <label>Are You Pregnant ?</label>
                      </div>

                      <div className="form-group" style={{ display: "inline" }}>
                        <div style={{ marginBottom: "30px" }}>
                          <div
                            className="form-check form-check-inline"
                            style={{ display: "inline" }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"

                              name="PregnancyStatus"
                              value={1}
                              onChange={(e) => {
                                handleChange(e);
                                // eslint-disable-next-line eqeqeq
                                e.target.value == 1
                                  ? setPregnancydeliverydateinput(true)
                                  : setPregnancydeliverydateinput(false);
                              }}
                              onBlur={handleBlur}
                            />
                            <label
                              style={{ paddingRight: "7px" }}
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"

                              name="PregnancyStatus"
                              value={0}
                              onChange={(e) => {
                                handleChange(e);
                                e.target.value == 1
                                  ? setPregnancydeliverydateinput(true)
                                  : setPregnancydeliverydateinput(false);
                              }}
                              onBlur={handleBlur}
                            />
                            <label
                              style={{ paddingRight: "7px" }}
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Pregnancydeliverydateinput ? (
                    <>
                      <div>
                        <label>Delivery Expected Date ?</label>
                      </div>

                      <div className="form-group">
                        <input
                          type="date"
                          name="ExpectedDeliveryDate"
                          id="ExpectedDeliveryDate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ExpectedDeliveryDate}
                          className="form-control"
                          placeholder="DOB"
                        />
                        {errors.ExpectedDeliveryDate &&
                          touched.ExpectedDeliveryDate ? (
                          <div style={{ color: "red" }}>
                            {errors.ExpectedDeliveryDate}
                          </div>
                        ) : null}
                        <div className="help-block with-errors" />
                      </div>
                    </>
                  ) : null}

                  <button className="btn btn-success btn-block" type="submit">
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setstudentcon(true);
              setSecmodal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={Visiblt}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="shadow p-2 mb-5 bg-white ">
            <center style={{ color: "#27AE60" }}>
              <h4 style={{ fontWeight: "bold" }}> Student Details</h4>
            </center>
          </div>
          {datafi ? (
            <>
              <div className="row">
                <div className="col-5 text-left">Roll Number</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.rollNo}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Name</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.studentName}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Father Name</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.fatherName}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Dob</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.dob}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Email</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.emailId}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Mobile Number</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.mobileNo}</div>
              </div>

              <div className="row">
                <div className="col-5 text-left">Physical Disability</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {datafi.physicalDisability == 1 ? "Yes" : "No"}
                </div>
              </div>
              {datafi.physicalDisability == 1 ? (
                <>
                  <div className="row">
                    <div className="col-5 text-left">Disability Mobility</div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {datafi.disabilityMobility == 1 ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">Disability Percentage</div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {datafi.disabilityPercentage}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">Visibility Impaired</div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {datafi.visibilityImpaired == 1 ? "yes" : "No"}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="row">
                <div className="col-5 text-left">Gender</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.genderName}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">class Name</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{datafi.className}</div>
              </div>
              {datafi.genderId == 2 ? (
                <>
                  <div className="row">
                    <div className="col-5 text-left">
                      Female Marriage Status
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {datafi.marriageStatus == 1 ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">
                      Female Pregnant Status
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {datafi.isPregnant == 1 ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">
                      Delivery Expected Date
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {datafi.expectedDeliveryDate}
                    </div>
                  </div>
                </>
              ) : null}

              {/* <div className="row">
      <div className="col-5 text-left">Name</div>
      <div className="col-2 text-left">:</div>
      <div className="col-5 text-left">{datafi.rollNo}</div>
       </div> */}
            </>
          ) : (
            <p>Loding....</p>
          )}
          <br />
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              setSecmodal(true);
              setVisiblt(false);
              setedit(true);
            }}
          >
            Update
          </button>
          <br />
          <button
            className="btn btn-success btn-block"
            onClick={() => {
              isConfirm();
            }}
          >
            Confirm
          </button>
        </Modal.Body>
      </Modal>
      {on ? (
        <DndProvider backend={HTML5Backend}>
          <ChakraProvider>
            <SchoolBlock RollNo={StudentUentityId} />
          </ChakraProvider>
        </DndProvider>
      ) : null}
    </>
  );
};
const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  AppMapStateToProps,
  AppMapDispatchToProps
)(StudentListForm);
