import React, { useEffect, useState, useRef } from 'react';
import { CCard, CCardBody, CCardHeader, CBadge } from "@coreui/react";
import { Container, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import ExportApis from "src/Constants/Apis/ExportApis";
import { BeatLoader } from "react-spinners"
import { getRollno } from "src/Constants/Redux/Actions/Authentication";
import { ToastContainer, toast } from 'react-toastify';
import Updatestudent from "./Updatestudent";

//import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { PrintAllotmentLetter } from './School/PrintLetter';
const validationSchema = Yup.object().shape({
  studentName: Yup.string().required('Name is required!'),
  pinCode: Yup.string().required('Pincode is required!'),
  fatherName: Yup.string().min(2, 'Too Short!').required('Father Name is required!'),
  motherName: Yup.string().min(2, 'Too Short!').required('Mother Name is required!'),
  dob: Yup.date().required('DOB is required!'),
  genderid: Yup.number().min(1).required('Gender is required!'),
  mobileNo: Yup.string().required('Mobile No is required!').min(10)
    .max(10, 'max can 10'),
  emailId: Yup.string().min(1).required('Email is required!'),
  adharNo: Yup.string()
    .required('Adhar No is required!')
    .min(12)
    .max(12, 'max can 12')
});


function ReRunStudent({ ...props }) {
  const [StudentList, setStudentList] = useState();
  const [Stundentfinaldata, setStundentfinaldata] = useState();
  const [Stundentfinaldata1, setStundentfinaldata1] = useState();
  const [Stundentdata, setStundentdata] = useState();
  const [Centerdata, setCenterdata] = useState();
  const [Modalvisiblity, setModalvisiblity] = useState(false);
  const [updateModal, setupdateModal] = useState(false);
  const [studentupdateModal, setstudentupdateModal] = useState(false);
  //const notify = () => toast.success('Choice Confirmed !')
  //const notifyupdate = () => toast.success('Sucessfully Updated!')
  const [Modalvisiblityupdate, setModalvisiblityupdate] = useState(false);



  const GetStudentList = () => {

     console.log("Stud userId........ rerun", props);
    ExportApis.GetReRunstudentlist(props.userDetail, 3, 0).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          //   console.log("Student List jbt1........", Data);
          setStudentList(Data)
          // console.log("CHEK",props.userDetail)
          setstudentupdateModal(false)
        }
      }
    );
  };

  const handlesingleStudentdata1 = (uentityId) => {
    ExportApis.StudentDetails(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setStundentfinaldata(Data)
          //  console.log("singleData List........", Data);
        }
      })

    ExportApis.SchoolRequest(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          console.log('center data....', Data)
          setCenterdata(Data)
          // setupdateModal(true)
          setModalvisiblity(true)
          // setModalvisiblity(true)

          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  }

  const handleStudentchoicedata = (uentityId) => {
    ExportApis.StudentDetails(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          console.log('update choice data....', Data)
          setStundentfinaldata(Data)
          //console.log("singleData List........", Data);
        }
      })

    ExportApis.SchoolRequest(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setCenterdata(Data)
          setupdateModal(true)
          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  }

  const UpdatStudentedata = (uentityId) => {
    // console.log("uid", uentityId)
    setStundentfinaldata(null)
    ExportApis.StudentDetails(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setStundentfinaldata(Data)
          setstudentupdateModal(true)
          // console.log("singleData List........",Data );
        }
      })


  }
  useEffect(() => {
    GetStudentList()
  }, []);



  const HandleStudentForm = (data) => {
    //console.log(data);
    setStundentdata(data)
    /// <Redirect to="/StudentListForm/"/>
    props.CurrentUserRollnum(data.rollNo)
    props.history.push("/StudentListForm/" + data.rollNo+ "/" + 3);
  };

  const HandleStudentcenter = (data) => {
    setStundentdata(data)
    props.CurrentUserRollnum(data.rollNo)
    props.history.push("/CallForm/" + data.rollNo + "/" + 3);

  };

  const Handlefinalchoice = (data) => {
    ExportApis.ChoicesConfirm(data.uentityId, 1)
      .then((resp) => {
        if (resp.ok) {

          setStundentdata(data)
          // notify();
          // props.history.push("/");
          GetStudentList();
          // setVisible(false);
          //  console.log("form value data show*****");
        } else {
          alert("err");
        }
      })

      .catch((err) => console.log(err));
  }

  // handleSingleDetele = () => {
  //   setState({ secmodal: false });
  //  setState({ showsingledetele: true });
  // };
  // handleSingleDeteleModalHide = () => {
  //   setState({ secmodal: false });
  //   this.setState({ showsingledetele: false });
  // };
  // UpdeteSingleDeteleModalHide = () => {
  //  setState({ secmodal: true });
  //  setState({ showsingledetele: false });
  // Deletechoice
  // };


  const DeleteStudentcenter = (uentityId) => {
    console.log("uid", uentityId)
    ExportApis.Deletechoice(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          //console.log("data",Data)
          // props.history.push("/");
          GetStudentList();
          setupdateModal(false)

          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  };
  // const Updatedata = async (values, resetForm) => {
  //   await ExportApis.UpdateStudentdata(
  //     values.uentityId,
  //     values.studentName,
  //     values.pinCode,
  //     values.fatherName,
  //     values.motherName,
  //     values.dob,
  //     values.genderid,
  //     values.mobileNo,
  //     values.emailId,
  //     values.adharNo

  //   )
  //     .then((resp) => {
  //       if (resp.ok) {
  //        // let Data = resp.data
  //        setstudentupdateModal(false)
  //        notifyupdate();




  //       }
  //     })
  //     .catch((err) => console.log(err));


  // };




  return (
    <>
      <CCard>
        <CCardHeader >Student List<strong> (Subject : Re-Run (2021-2023) Student Mercy chance)</strong></CCardHeader>
        <CCardBody className="table-responsive">

          {StudentList && StudentList.length > 0 ? (
            <table className="table table-hover table-outline  mb-0 ">
              <thead >
                <tr>
                  <th className="text-center">Sr.No</th>
                  <th className="text-center">Code</th>
                  <th className="text-center">Roll No</th>
                  <th className="text-center">Student Name</th>
                  <th className="text-center">Father Name</th>
                  <th className="text-center">Class</th>
                  <th className="text-center">Session</th>
                  <th className="text-center">Alloted School </th>
                  <th className="text-center">Action</th>
                  <th className="text-center">Profile</th>
                  <th className="text-center">Details</th>
                  <th className="text-center">Allotment Letter</th>

                </tr>
              </thead>

              <tbody>
                {StudentList.map((data, i) => (
                  <tr key={data.uentityId}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{data.uentityId}</td>
                    <td className="text-center">{data.rollNo}</td>
                    <td className="text-center">{data.name}</td>
                    <td className="text-center">{data.fatherName}</td>
                    <td className="text-center">{data.className}</td>
                    <td className="text-center">{data.sessionName}</td>
                    <td className="text-center">{data.alloatedSchool}</td>

                    <tr>
                      <td className="text-center">
                        <button
                        //  disabled={data.alloatedSchool == null ? false : true} 
                          className="btn btn-warning text-white  btn-sm "
                          onClick={() => {
                            handleStudentchoicedata(data.uentityId);
                          }}>
                          Update Choice
                        </button>
                      </td>
                      {/* <td><button className="btn btn-info text-white btn-sm"
                        onClick={() => {
                          UpdatStudentedata(data.uentityId);
                        }}



                      >
                        Update Profile</button>
                      </td> */}
                    </tr>
                    <td className="text-center">
                      {data.choicesConfirm === 1 ? (
                        <span className="badge badge-pill badge-dark">Completed</span>
                      ) : data.isConfirm === null || data.isConfirm === 0 ? (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => HandleStudentForm(data)}
                        >
                          Set Profile
                        </button>
                      ) : data.centerConfirm === null || data.centerConfirm === 0 ? (
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() => HandleStudentcenter(data)}
                        >
                          Set School
                        </button>
                      ) : <button
                        className="btn btn-danger btn-sm"
                        onClick={() => Handlefinalchoice(data)}
                      >
                        Check permission
                      </button>}
                    </td>
                    <td className="text-center">
                      {data.choicesConfirm === 1 ? (
                        <button
                          onClick={() => {
                            handlesingleStudentdata1(data.uentityId);
                          }}
                          className="btn btn-success btn-sm"
                        >
                          View Profile
                        </button>
                      ) : null}

                      {/* <button onClick={() => PrintAllotmentLetter(data.uentityId)}>PrintDetail</button> */}
                    </td>
                    <td className="text-center">
                     
                      <button
                        onClick={() => PrintAllotmentLetter(data.uentityId)}
                          className="btn btn-info btn-sm"
                        >
                         Print Allotment
                      </button> 

                      {/* <button onClick={() => PrintAllotmentLetter(data.uentityId)}>PrintDetail</button> */}
                    </td>  

                    {/* <td><button className="btn btn-info text-white btn-sm"
                        onClick={() => {
                          // UpdatStudentedata(data.uentityId);
                          props.history.push("/StudentConditions");

                        }}



                      >
                        Student Forms</button>
                      </td> */}

                  </tr>
                ))}
              </tbody>
            </table>
          ) : <BeatLoader color="blue" loading />
          }
        </CCardBody>
      </CCard>

      <Modal
        show={Modalvisiblity}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="shadow p-2 mb-5 bg-white ">
            <center style={{ color: "#27AE60" }}>
              <h4 style={{ fontWeight: "bold" }}> Student Details</h4>
            </center>
          </div>
          {Stundentfinaldata ? (
            <>
              <div className="row">
                <div className="col-5 text-left">Roll Number</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.rollNo}
                </div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Name</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.studentName}
                </div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Father Name</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.fatherName}
                </div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Dob</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">{Stundentfinaldata.dob}</div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Email</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.emailId}
                </div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Mobile Number</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.mobileNo}
                </div>
              </div>
              <div className="row">
                <div className="col-5 text-left">Physical Disability</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.physicalDisability == 1 ? "Yes" : "No"}
                </div>
              </div>
              {Stundentfinaldata.physicalDisability == 1 ? (
                <>
                  <div className="row">
                    <div className="col-5 text-left">Disability Mobility</div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {Stundentfinaldata.disabilityMobility == 1
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">
                      Disability Percentage
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {Stundentfinaldata.disabilityPercentage}%
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">Visibility Impaired</div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {Stundentfinaldata.visibilityImpaired == 1
                        ? "yes"
                        : "No"}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="row">
                <div className="col-5 text-left">Gender</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {" "}
                  {Stundentfinaldata.genderName}
                </div>
              </div>
              <div className="row">sssssssssssssssssssssssssssssssssssssssssssssssssss
                <div className="col-5 text-left">Class Name</div>
                <div className="col-2 text-left">:</div>
                <div className="col-5 text-left">
                  {Stundentfinaldata.className}
                </div>
              </div>
              {Stundentfinaldata.genderId == 2 ? (
                <>
                  <div className="row">
                    <div className="col-5 text-left">
                      Female Marriage Status
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {Stundentfinaldata.marriageStatus === 1 ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">
                      Female Pregnant Status
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {Stundentfinaldata.isPregnant === 1 ? "Yes" : "No"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 text-left">
                      Delivery Expected Date
                    </div>
                    <div className="col-2 text-left">:</div>
                    <div className="col-5 text-left">
                      {Stundentfinaldata.expectedDeliveryDate}
                    </div>
                  </div>
                </>
              ) : null}

              <br />
              <br />

              <div className="shadow p-2 mb-5 bg-white ">
                <center style={{ color: "#27AE60" }}>
                  <h4 style={{ fontWeight: "bold" }}>Priority</h4>
                </center>
              </div>
              <br />
              <br />

              {Centerdata?.map((item) => (

                <>

                  <div className="row">
                    <div className="col-2 text-left" style={{ fontWeight: "bold" }}>{item.priority}</div>

                    <div className="col-10 ">{item.schoolName}</div>
                  </div>
                  <hr />
                </>
              ))}
            </>
          ) :
            <BeatLoader loading />
            //  <p>Loding....</p>
          }
          <br />
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              setModalvisiblity(false)
            }}
          >
            Cancel
          </button>
          <br />
        </Modal.Body>
      </Modal>
      <Modal
        show={updateModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>

          {Stundentfinaldata ? (
            <>

              <div className="shadow p-2 mb-5 bg-white ">
                <center style={{ color: "#27AE60" }}>
                  <h4 style={{ fontWeight: "bold" }}>Priority</h4>
                </center>
              </div>
              <center> <div><h4 style={{ fontWeight: "bold", color: "red" }}>All Selected Choices Will Be Deleted  </h4></div></center>
              <br />
              <br />

              {Centerdata?.map((item) => (

                <>

                  <div className="row">
                    <div className="col-2 text-left" style={{ fontWeight: "bold" }}>{item.priority}</div>

                    <div className="col-10 ">{item.schoolName}</div>
                  </div>
                  <hr />
                </>
              ))}
            </>
          ) :
            <p>loding</p>

          }
          <br />
          <button
            className="btn btn-danger btn-block"
            onClick={() => {
              setupdateModal(false)
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              DeleteStudentcenter(Stundentfinaldata.uentityId)
            }}
          >
            Confirm
          </button>
          <br />
        </Modal.Body>
      </Modal>



      <Modal
        show={studentupdateModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
          <div className="shadow p-2 mb-5 bg-white ">
            <center style={{ color: "#27AE60" }}>
              <h4 style={{ fontWeight: "bold" }}>Update student profile</h4>
            </center>
          </div>

          <Updatestudent dataupdate={Stundentfinaldata} GetStudentList={GetStudentList} />


          <button
            className="btn btn-danger btn-block"

            onClick={() => {

              setstudentupdateModal(false)
            }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>






      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}
const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
    userRollNumber: state.authentication.rollNumber,

  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    CurrentUserRollnum: (rollNumber) => dispatch(getRollno(rollNumber))
  };
};


export default connect(AppMapStateToProps, AppMapDispatchToProps)(ReRunStudent);

