import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { connect } from 'react-redux'
import { LogoutFunction } from 'src/Constants/Redux/Actions/Authentication'
import { useHistory } from 'react-router'
import user from './../assets/user.png'
import bhiwani from './../assets/bhivani.png'
const TheHeaderDropdown = (props) => {
  const history = useHistory();

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mr-5"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link mr-3" caret={false}>
        <div className="c-avatar">
          <CImg
            src={user}
            className="c-avatar-img  ml-5"
          // alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          Comments
          <CBadge color="warning" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center mr-3"
        >
          <strong>User</strong>
        </CDropdownItem>
        {/* <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />Profile
        </CDropdownItem> */}
        {/* <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-credit-card" className="mfe-2" />
          Payments
          <CBadge color="secondary" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-file" className="mfe-2" />
          Projects
          <CBadge color="primary" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        <CDropdownItem divider />
        <CDropdownItem onClick={() => props.logOut(history)}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown >
  )
}

const ProductMapStateToProps = state => {
  return {
    currentUser: state.authentication.currentUser,
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
    logOut: (history) => { dispatch(LogoutFunction(history)) },
  };
};

export default connect(ProductMapStateToProps, AppMapDispatchToProps)(TheHeaderDropdown)
