import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

function ProtectedRoutes({ component: Component, ...rest }) {
  const user = localStorage.getItem("user");
  return (
    <Route {...rest} render={(props) => {
        if (user) {
          return (
            <Component {...props} />
          )
        }
        else {

          return <Redirect to={{ pathname: "/login" }} />
        }
      }}
    />
  );
}


export default withRouter(ProtectedRoutes);
