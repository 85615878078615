//import React from "react";
import ExportApis from "src/Constants/Apis/ExportApis";
import jwt_decode from "jwt-decode";
import { BaseApi } from "src/Constants/Apis/BaseApi";
export const LoginAction = (user) => ({
  type: "LOGIN",
  user,
});

export const getCurrentUser = (currentUser) => ({
  type: "GETCURRENTUSER",
  currentUser,
});

export const getRollno = (rollNumber) => ({
  type: "ROLLNUMBER",
  rollNumber,
});


export const getStudentType = (studenttype) => ({
  type: "StudentType",
  studenttype,
});

export const isDashbord = (isDashboard) => ({
  type: "isDashboard",
  isDashboard,
});

export const authAction = (isAuth) => ({
  type: "AUTH",
  isAuth,
});

export const invalidUserErrorAction = (msg) => ({
  type: "INVALIDUSER",
  msg,
});

export const LoginFunction = (username, password, history) => {
  return (dispach) => {
    dispach(invalidUserErrorAction(""));
    ExportApis.LoginJBT(username, password)
      .then((resp) => {
        console.log("object of login",resp)
        if (resp.ok && resp.data.uEntityId !== 0) {
          dispach(authAction(true));
          localStorage.setItem("user", resp.data.token);
          let token = localStorage.getItem("user");
          let decoded = jwt_decode(token);
          let uEntityId = parseInt(decoded.name);
          dispach(getCurrentUser(uEntityId));
          BaseApi.setHeader("Authorization", `BEARER ${token}`);
          // console.log("decoded", decoded.unique_name);
          history.push("/dashboard");
        } else {
          dispach(invalidUserErrorAction("Invalid Credintials."));
        }
      })
      .catch((err) => console.log(err));

  };
};

export const GetCurrentUserDetails = (history) => {
  return (dispach) => {
    const currentUserId = parseInt(localStorage.getItem("user"));
    console.log("object of currentUserId",currentUserId)
    if (currentUserId) {
      ExportApis.GetCurrentUserDetail(currentUserId)
        .then((resp) => {
          console.log("resp......",resp)
          if (resp.ok) {
            dispach(getCurrentUser(resp.data));
          }
          if (resp.status === 401) {
            console.log("user detail 401 error");
            alert("Sorry you are not authorized. Please Login Again.");
            dispach(LogoutFunction(history));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispach(getCurrentUser(""));
    }
  };
};

export const UpdateCurrentUserDetails = (
  FirstName,
  MobileNo,
  EmailId,
  AddressLine1,
  AddressLine2
) => {
  return (dispach) => {
    const currentUserId = parseInt(localStorage.getItem("user"));
    if (currentUserId) {
      ExportApis.UpdateCurrentUserDetail(
        currentUserId,
        FirstName,
        MobileNo,
        EmailId,
        AddressLine1,
        AddressLine2
      )
        .then((resp) => {
          if (resp.ok) {
            dispach(getCurrentUser(resp.data));
          } else {
            // dispach(invalidUserErrorAction("Invalid Credintials."));
          }
        })
        .catch((err) => console.log(err));
    }
  };
};

export const LogoutFunction = (history) => {
  return (dispach) => {
    const username = "";
    dispach(LoginAction(username));
    dispach(authAction(false));
    localStorage.removeItem("user");
    // localStorage.removeItem("token");
    history.push("/login");
  };
};
