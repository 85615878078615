import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./Pages/Authentication/Login";
import { getCurrentUser } from "src/Constants/Redux/Actions/Authentication";
import { connect } from "react-redux";
import "./scss/style.scss";
import ForgotPassword from "./ForgotPassword";
import NewsD from "./Pages/NEWS/NewsD";
import jwt_decode from "jwt-decode";
import { BaseApi } from "./Constants/Apis/BaseApi";
import TheLayout from "./containers/TheLayout";
import Order from "./Pages/Dashboard/Order";
//import Newstudent from "./Pages/Student/Newstudent";

// const Loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// );

// Containers
// const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import("./views/pages/register/Register"));
// const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
// const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("user");
    if (token) {
      //this.props.CurrentUserDetail(JSON.parse(localUser));
      console.log("token------", token);
      var decoded = jwt_decode(token);
      console.log("decode",decoded)
      const uEntityId = parseInt(decoded.name);
      console.log("object",uEntityId)
      this.props.CurrentUserDetail(uEntityId);
      BaseApi.setHeader("Authorization", `BEARER ${token}`);
    }

    this.setState({ loaded: true })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div className="pt-3 text-center">
          <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
      )
    }
    return (
      <BrowserRouter>
        <Switch>
        <Route
            exact
            path="/order"
            name="order"
            render={(props) => <Order {...props} />}
          />
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/ForgotPassword"
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />

          <Route
            exact
            path="/NewsD"
            name="NewsD"
            render={(props) => <NewsD {...props} />}
          />
          {/* <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            /> */}

          {/* <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          /> */}
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        
        </Switch>
      </BrowserRouter>
    );
  }
}

const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    CurrentUserDetail: (currentUser) => dispatch(getCurrentUser(currentUser)),
  };
};

export default connect(AppMapStateToProps, AppMapDispatchToProps)(App);
