import React, { Component } from 'react'
import { Link } from "react-router-dom"
import {
  CCard,
  CCardBody,
} from '@coreui/react'
import ExportApis from 'src/Constants/Apis/ExportApis';
import { connect } from 'react-redux';



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collage: "",
      total: "",
      Notification: [],
  
    }
  }

  componentDidMount() {
    //console.log("UserData", this.props.userDetail)
    // let token = localStorage.getItem("user");
    // let decoded = jwt_decode(token);
    // let uEntityId=parseInt(decoded.unique_name)
    this.GetStudentList();
    this.GetTotalCount();
    this.GetNotification();
    //console.log("decoded",  decoded.unique_name);

    //console.log('user data ......', JSON.parse(localStorage.getItem('user')))
  }


  GetNotification = () => {
    ExportApis.GetNotification(4).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
    
          this.setState({ Notification : Data });
          console.log("notification ......", Data);
        }
      }
    );
  };




  GetStudentList = () => {
    ExportApis.Dashboard(this.props.userDetail).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          // console.log("collage D........", Data);
          this.setState({ collage: Data });
        }
      }
    );
  };

  GetTotalCount = () => {
    ExportApis.Totaldata(this.props.userDetail).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          //  console.log("Total collage D........", Data);
          this.setState({ total: Data });
        }
      }
    );
  };


  render() {
    const {  Notification,test } = this.state;

    const { userDetail } = this.props
    // console.log("userDetail", userDetail)

    // var token = localStorage.getItem("user");
    //  var decoded = jwt_decode(token);
    //  const uEntityId =decoded.unique_name

    // console.log("decoded",  decoded.unique_name);

    return (
      <>
        <CCard className="bg-info">
          <CCardBody className="text-center">
            <h3>Welcome to</h3>
            <h4>{this.state.collage?.collegeName}</h4>
            <h6>{this.state.collage?.locationName}</h6>
          </CCardBody>
        </CCard>

        <div className="item col-xs-12 col-lg-12">
        <div className="panel panel-default paper-shadow" data-z="0.5">
          <div className="panel-heading">
            <div className="media v-middle">
              <div className="media-body">
                <h4 className="text-headline margin-none">Notifcation</h4>
                <p className="text-subhead text-light">Latest News</p>
              </div>
              {/* <div className="media-right">
                  आपको सूचित किया जाता है कि सर्वप्रथम आपको प्रवेश वर्ष/सत्र 2022-24 के विद्यार्थियों की जानकारी भरनी है। 
              </div> */}
            </div>
          </div>
          <div className="table-responsive">
          {Notification.map((data, i) => (
              <table className="table text-subhead v-middle">
                <tbody>
                  <tr>
                    <td className="width-100 text-caption">
                      <div className="label label-grey-200 label-xs"><p>{data.name}</p></div>
                    
                    </td>
                    {/* <td className="width-80 text-center">{data.name}</td> */}
                    {/* <td className="width-80 text-center">
                    <a href="#">#9936</a>
                  </td> */}
                    <td className="width-50 "><div className='align-items-center'>

                      <a className="fa fa-download"
                        href={data.image}
                      
                      >
                        {/* Details-- */}
                        {/* <p className="text-subhead text-light">Details <i className="fa fa-download" /> </p> */}

                       
                    
                      {/* <img
                        src={
                          data.image
                        }
                        className='rounded-lg mr-2'
                        width='80'
                        height='50'
                        alt=''
                      /> */}
                        </a>
                    </div></td>

                  </tr>

                </tbody>
              </table>
           ))}
          </div>
        </div>
      </div>
      

        <div className="row" >


          <div className="col-md-4 " >

            <CCard
            >
              <CCardBody className="text-center" >
                <h5>Total Students</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                  <strong style={{ fontSize: "20px", fontStyle: "bold" }} >{this.state.total?.totalStudent}</strong>
                </Link>
              </CCardBody>
            </CCard>
          </div>
          <div className="col-md-2">
            <CCard >
              <CCardBody className="text-center">
                <h5   >Boys</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                <strong style={{ fontSize: "20px" }} >{this.state.total?.totalMale}</strong>
                </Link>
              
              </CCardBody>
            </CCard>
          </div>
          <div className="col-md-2">
            <CCard >
              <CCardBody className="text-center">
                <h5   >Girls</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                <strong style={{ fontSize: "20px" }} >{this.state.total?.totalFemale}</strong>
                </Link>            
              </CCardBody>
            </CCard>
          </div>
          <div className="col-md-4">
            <CCard >
              <CCardBody className="text-center">
                <h5   >Total Pregnant</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                <strong style={{ fontSize: "20px" }} >{this.state.total?.totalPragnet}</strong>
                </Link>
               
              </CCardBody>
            </CCard>
          </div>
        </div>
        <div className="row" >

          <div className="col-md-4">
            <CCard >
              <CCardBody className="text-center">
                <h5   >Married Students</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                <strong style={{ fontSize: "20px" }} >{this.state.total?.marriedStudent}</strong>
                </Link>
              

              </CCardBody>
            </CCard>
          </div>
          <div className="col-md-4">
            <CCard >
              <CCardBody className="text-center">
                <h5   >Total Disabled Students</h5>
                <Link to={{ pathname: '/StudentList', }} className='btn btn-success shadow btn-xs sharp mr-1'>
                <strong style={{ fontSize: "20px" }} >{this.state.total?.totaldisable}</strong>
                </Link>
              </CCardBody>
            </CCard>
          </div>
          {/* <div className="col-md-4">
            <CCard  >
              <CCardBody className="text-center">
                <h5>.</h5>
                <strong style={{ fontSize: "20px" }} >0</strong>
              </CCardBody>
            </CCard>
          </div> */}
        </div>
      </>
    )
  }
}

const AppMapStateToProps = state => {
  return {
    userDetail: state.authentication.currentUser,
  };
};

const AppMapDispatchToProps = dispatch => {
  return {
  };

}

export default connect(AppMapStateToProps, AppMapDispatchToProps)(Dashboard)
