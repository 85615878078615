import React from 'react'
//import { Flex, Heading, List, Stack, Box } from "@chakra-ui/react";
//import { useEffect,useState } from "react";
//import ExportApis from "src/Constants/Apis/ExportApis";

export const Finalchoice = () => {
   // const [datafi, setDatafi] = useState({});
  return (
    <div>  
        fggg
    </div>
  )
}
