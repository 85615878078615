/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  auth: false,
  isDashboard: false,
  msg: '',
  user: [],
  currentUser: {},
  rollNumber: {},
  studenttype: 1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.user
      };

    case "GETCURRENTUSER":
      return {
        ...state,
        currentUser: action.currentUser
      };

    case "AUTH":
      return {
        ...state,
        auth: action.isAuth
      };
    case "ROLLNUMBER":
      return {
        ...state,
        rollNumber: action.rollNumber
      };

    case "StudentType":
      return {
        ...state,
        studenttype: action.studenttype
      };

    case "INVALIDUSER":
      return {
        ...state,
        msg: action.msg
      };

    case "isDashboard":
      return {
        ...state,
        isDashboard: action.isDashboard
      };

    default: return state
  }
}
