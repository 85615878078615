import React, { useEffect, useState } from 'react';
//import { Col, Card, Table, Modal, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ExportApis from 'src/Constants/Apis/ExportApis';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Roll No  is required!'),
  Name: Yup.string().min(2, 'Too Short!').required('Name is required!'),
  address: Yup.string().min(2, 'Too Short!').required('Address is required!'),
  PinCode: Yup.string().required('Pincode is required!'),
  fatherName: Yup.string().min(2, 'Too Short!').required('Father Name is required!'),
  motherName: Yup.string().min(2, 'Too Short!').required('Mother Name is required!'),
  address: Yup.string().min(2, 'Too Short!').required('Address is required!'),
  genderid: Yup.number().min(1).required('Gender is required!'),
  dob: Yup.date().required('DOB is required!'),
  classid: Yup.number().min(1).required('Class is required!'),
  studentTypeid: Yup.number().min(1).required('studentType is required!'),
  sessionid: Yup.number().min(1).required('Session is required!'),
  totalmarks: Yup.number().min(1).required('Marks is required!'),
  AdharNo: Yup.string()
    .required('Adhar No is required!')
    .min(12)
    .max(12, 'max can 12')

});

function StudentConditions({ ...props }) {
  const notify = () => toast.success('Sucessfully Inserted!')
  const errormsg = (Data) => toast.error(Data
    , {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [isPost, setIsPost] = useState(true);
  const [editData, setEditData] = useState('');

  //   post data function
  const postData = async (values, resetForm) => {
    await ExportApis.Newstudentinsert(
      values.code,
      values.Name,
      values.address,
      values.PinCode,
      values.fatherName,
      values.motherName,
      values.genderid,
      values.dob,
      values.classid,
      values.studentTypeid,
      values.sessionid,
      values.totalmarks,
      values.AdharNo,
      props.userDetail

    )
      .then((resp) => {
        if (resp.ok) {
          let Data = resp.data
          if (Data == "Inserted Successfully !") {
            resetForm();
            notify();
          }
          else {
            resetForm();
            errormsg(Data);

          }
        }
      })
      .catch((err) => console.log(err));


  };

  useEffect(() => {
    // console.log("UseddrData",props.data)
    // getDataHandler();
    // getapprovalType();
  }, []);

  // if (!Data) {
  //   return <div>Loading..</div>;
  // }


  return (
    <>
      <div className="card border-warning mb-3">
        <div className="card-header bg-info text-white">विद्यालय अनुभव कार्यक्रम</div>
        <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div>
        <div className="card-body ">
          <Formik
            initialValues={{
              code: '',
              Name: '',
              address: '',
              PinCode: '',
              fatherName: '',
              motherName: '',
              dob: '',
              classid: '',
              studentTypeid: '',
              sessionid: '',
              genderid: '',
              totalmarks: '',
              AdharNo: ''
            }}

            onSubmit={(values, { resetForm }) => {
              // same shape as initial values
              // isPost ? postData(values, resetForm) : putData(values, resetForm);
              postData(values, resetForm);
            }}
            validationSchema={validationSchema}

          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="code">Roll No</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Roll No "
                        name="code"
                        id="code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                      />
                      {errors.code && touched.code ? (
                        <div style={{ color: "red" }}>{errors.code}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="sessionid"> Session</label>
                      <select
                        name='sessionid'
                        id='sessionid'
                        className='form-control input-default'
                        onBlur={handleBlur}
                        value={values.sessionid}
                        onChange={handleChange}
                      >
                        <option selected> Select Session</option>
                        <option value={2}>2020-2022</option>
                        <option value={1}>2021-2023</option>
                      </select>
                      {errors.sessionid && touched.sessionid ? (
                        <div style={{ color: "red" }}>{errors.sessionid}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="Gender"> Gender</label>
                      <select
                        name='genderid'
                        id='genderid'
                        className='form-control input-default'
                        onBlur={handleBlur}
                        value={values.genderid}
                        onChange={handleChange}
                      >
                        <option selected> Select Gender</option>
                        <option value={1}>Male</option>
                        <option value={2}>Female</option>
                        <option value={3}>Other</option>
                      </select>
                      {errors.genderid && touched.genderid ? (
                        <div style={{ color: "red" }}>{errors.genderid}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="classid"> Class</label>
                      <select
                        name='classid'
                        id='classid'
                        className='form-control input-default'
                        onBlur={handleBlur}
                        value={values.classid}
                        onChange={handleChange}
                      >
                        <option selected> Select Class</option>
                        <option value={2}>JBT-1</option>
                        <option value={1}>JBT-2</option>
                      </select>
                      {errors.classid && touched.classid ? (
                        <div style={{ color: "red" }}>{errors.classid}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="studentTypeid"> Student Type</label>
                      <select
                        name='studentTypeid'
                        id='studentTypeid'
                        className='form-control input-default'
                        onBlur={handleBlur}
                        value={values.studentTypeid}
                        onChange={handleChange}
                      >
                        <option selected> Select Type</option>
                        <option value={1}>Regular</option>
                        <option value={2}>Previous</option>
                        <option value={3}>Re-Run</option>
                      </select>
                      {errors.studentTypeid && touched.studentTypeid ? (
                        <div style={{ color: "red" }}>{errors.studentTypeid}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="rollno">Name</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        name="Name"
                        id="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.Name}
                      />
                      {errors.Name && touched.Name ? (
                        <div style={{ color: "red" }}>{errors.Name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="fathername">Father Name</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Father Name"
                        name="fatherName"
                        id="fatherName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fatherName}
                      />
                      {errors.fatherName && touched.fatherName ? (
                        <div style={{ color: "red" }}>{errors.fatherName}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="rollno">Mother Name</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Mother Name"
                        name="motherName"
                        id="motherName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.motherName}
                      />
                      {errors.motherName && touched.motherName ? (
                        <div style={{ color: "red" }}>{errors.motherName}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="AdharNo">Addhar No</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Addhar No"
                        name="AdharNo"
                        id="AdharNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.AdharNo}
                      />
                      {errors.AdharNo && touched.AdharNo ? (
                        <div style={{ color: "red" }}>{errors.AdharNo}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="dob">Date Of Birth</label>
                      <input type="date"
                        className="form-control"
                        placeholder="Enter DOB"
                        name="dob"
                        id="dob"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dob}
                      />
                      {errors.dob && touched.dob ? (
                        <div style={{ color: "red" }}>{errors.dob}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="PinCode">Pin Code</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Pincode"
                        name="PinCode"
                        id="PinCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.PinCode}
                      />
                      {errors.PinCode && touched.PinCode ? (
                        <div style={{ color: "red" }}>{errors.PinCode}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="Address">Address</label>
                      <input type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        name="address"
                        id="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                      {errors.address && touched.address ? (
                        <div style={{ color: "red" }}>{errors.address}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="totalmarks">Student Marks</label>
                      <input type="number"
                        className="form-control"
                        placeholder="Enter Marks"
                        name="totalmarks"
                        id="totalmarks"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.totalmarks}
                      />
                      {errors.totalmarks && touched.totalmarks ? (
                        <div style={{ color: "red" }}>{errors.totalmarks}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                <button type="submit" className="btn btn-primary "> Submit </button>
              </Form>
            )}
          </Formik>
        </div>

      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>

  )
}

const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
    userRollNumber: state.authentication.rollNumber,

  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(AppMapStateToProps, AppMapDispatchToProps)(StudentConditions);
