/**
 * Combine Reducers Redux Data
 */
import { combineReducers } from 'redux';
import Authentication from '../Reducers/Authentication';
import changeState from '../Reducers/changeState';

// Create Custome Reducers

export const rootReducer = combineReducers({
  authentication: Authentication,
  changeState: changeState,
});
