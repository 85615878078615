import { create } from 'apisauce';

const BaseApi = create({

  //baseURL: 'http://localhost:46405/api/',
 //baseURL: 'http://localhost:5000/api/',
  baseURL: 'https://jbtapi.kmaschool.com/api/',

});

export {
  BaseApi,
}
