import React from 'react'
var myHeaders = new Headers();
myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjE4Mzk2MDAsImlzcyI6Imh0dHBzOi8vYXBpdjIuc2hpcHJvY2tldC5pbi92MS9leHRlcm5hbC9hdXRoL2xvZ2luIiwiaWF0IjoxNjQ2MjIyMTc2LCJleHAiOjE2NDcwODYxNzYsIm5iZiI6MTY0NjIyMjE3NiwianRpIjoiYWIwZnY4MHFEOXV4TmpVZyJ9.BZ3zLe5cpPyQwPue_tNhIILlnRrl8uMlazbEcGbidS4");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
    "billing_customer_name": "Sachin",
    "billing_last_name": "Singh",
    "billing_phone": 9899708906,
    "billing_address": "U11/10 dlf phase 3",
    "billing_address_2": "",
    "billing_pincode": 122002,
    "billing_city": "Gurgaon",
    "billing_email": "billing_email@gmail.com",
    "billing_country": "India",
    "billing_state": "Haryana",
    "breadth": 6,
    "length": 6,
    "height": 10,
    "weight": 0.4,
    "order_date": "2022-03-03T11:46:48.972Z",
    "order_id": 12220,
    "order_items": [
        {
            "name": "Try product",
            "sku": "12345",
            "units": 1,
            "selling_price": 165.25
        },
        {
            "name": "my product",
            "sku": "1234",
            "units": 1,
            "selling_price": 165.25
        }
    ],
    "payment_method": "COD",
    "shipping_is_billing": 1,
    "sub_total": 477
});

var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
};
    
function placeorder() {

    fetch("https://apiv2.shiprocket.in/v1/external/orders/create/adhoc", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}
export default function order() {
    return (
        <div className="text-center mt-3">
            <button type="button" className="btn btn-success" onClick={placeorder}>PLace Order</button>
        </div>
    )

}








