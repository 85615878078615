import { useEffect, useState } from "react";
import ExportApis from "src/Constants/Apis/ExportApis";

function News() {
  const [data,setData]=useState([])

    const handleSchool = () => {
        ExportApis.PostdataNews()
          .then((resp) => {
            if (resp.ok) {
              localStorage.setItem("News", JSON.stringify(resp.data));
              setData(resp.data);
            } else {
              alert("err");
            }
          })
          .catch((err) => console.log(err));
//        console.log("Success:......");
      };
    
      useEffect(() => {
        handleSchool();
    
        // handleState(id);
      }, []);
    return (  
<div>
<div className="text-justify">
                <h5>NEWS</h5>
                <h2
                  style={{
                    color: "#f57119",
                    fontWeight: "inherit",
                    marginTop: 7,
                  }}
                >
                 
{data ? (
              <ul>
                <li>
                  
                    <a style={{textDecoration:"none",lineHeight:"37px"}} href="/NewsD"> {data.tittle} </a>
                 
                </li>
              </ul>
            ) : (
              "loding"
            )}
                </h2>

                {/* <a className="btn btn-warning mt-3" href="https://play.google.com/store/apps/details?id=io.swapdeal.swap">Download App</a> */}
              </div>
</div>


    );
}

export default News;