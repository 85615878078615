import React, { useEffect, useState } from "react";

import { ChakraProvider } from "@chakra-ui/react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SchoolBlock from "./SchoolBlock";
import { connect } from "react-redux";
import { getRollno, getStudentType } from "src/Constants/Redux/Actions/Authentication";
import { withRouter } from "react-router-dom";


function CallForm(props) {
  //let UentityId = (props.location.pathname);
  // const StudentUentityId = parseInt(UentityId.substring(10));
  const [StudentUentityId, setStudentUentityId] = useState(null);
  const [studenttype, setstudenttype] = useState(null);
   console.log(StudentUentityId)
  // In React, after making the project live on the server, why does it get hidden when the page is referenced?

  useEffect(() => {

    const paths = props?.location?.pathname.split("/")

    setStudentUentityId(parseInt(paths[2]))
    setstudenttype(parseInt(paths[3]))

    console.log('student roll no.....', props?.location?.pathname?.substring(10))

    console.log('path name.....', paths[2])

  }, [props.location.pathname])

  props.CurrentUserRollnum(StudentUentityId)
  props.CurrentUserStudenttype(studenttype)
  return (



    <DndProvider backend={HTML5Backend}>
      <ChakraProvider>
        <SchoolBlock RollNo={StudentUentityId} studenttype={studenttype} />
      </ChakraProvider>
    </DndProvider>

  );
}

const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
    userRollNumber: state.authentication.rollNumber,
    studenttype: state.authentication.studenttype

  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    CurrentUserRollnum: (rollNumber) => dispatch(getRollno(rollNumber)),
    CurrentUserStudenttype: (studenttype) => dispatch(getStudentType(studenttype))
  };
};

export default connect(
  AppMapStateToProps,
  AppMapDispatchToProps
)(withRouter(CallForm));





