/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import bhiwani from "./../src/assets/bhivani.png";
import ExportApis from "./Constants/Apis/ExportApis";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import News from "./Pages/NEWS/News";

const ForgotPassword = () => {
  const [logindata, setLogindata] = useState(JSON.parse(localStorage.getItem("forget")));
  const [visible, setVisible] = useState(false);
 
  const [formshow, setFormshow] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  // let history = useHistory();
  // const handleOk = (history) => {
  //   setConfirmLoading(true);
  //   setTimeout(() => {
  //     setFormshow(false);
  //     setConfirmLoading(false);
  //     history.push("/Login");
  //   }, 2000);
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };
 
  const PostForgetPass = (values) => {
    ExportApis.PostdataForgetpassward(values.userCode)
      .then((resp) => {
        if (resp.ok) {
          console.log(resp.data);
          localStorage.setItem("forget", JSON.stringify(resp.data));
            setFormshow(true);
        } else {
          alert("err");
        }
      })
      .catch((err) => console.log(err));
    console.log("Success:......", values);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-sm-12 col-md-6" style={{ marginTop: "20px" }}>
            <img style={{ width: "100%" }} src={bhiwani} />
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: "80px", marginBottom: "20px" }}
        >
          <div className="col-sm-6 ">
            <h1> Forgot Password</h1>
            <p className="text-muted">Forgot Password your account</p>

            <Formik
              initialValues={{
                userCode: "",
              }}
              onSubmit={(values) => {
                PostForgetPass(values);
              }}
            >
              {({ errors, touched, values, handleChange, handleBlur }) => (
                <Form>
                  <div className="messages" />
                  <div className="form-group">
                    <input
                      type="text"
                      name="userCode"
                      id="userCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.userCode}
                      className="form-control"
                      placeholder="User Code"
                    />
                    {errors.userCode && touched.userCode ? (
                      <div style={{ color: "red" }}>{errors.userCode}</div>
                    ) : null}
                  </div>
                  {/* <div className="text-danger">{this.props.errorMsg}</div> */}
                  <button className="btn btn-primary btn-block" type="submit">
                    Forgot Password
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <br/>
          <br/>
          <div className="col-sm-6 shadow p-2  "style={{marginTop:"50px"}}>
            <News/>

              {/* <a className="btn btn-warning mt-3" href="https://play.google.com/store/apps/details?id=io.swapdeal.swap">Download App</a> */}
            </div>
          
        </div>
        <Modal
          show={formshow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        
          <Modal.Body>
            <h6> your Passward is :{logindata?logindata:"loding..."}</h6>
          </Modal.Body>
          <Modal.Footer>
           
            <Button href="/login" onClick={() => setFormshow( false )}>
            Confirm
            </Button>
           
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ForgotPassword;
