import { Flex, Heading, List, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useDrop } from "react-dnd";
import Player from "./Player";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import ExportApis from "src/Constants/Apis/ExportApis";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"


function SchoolBlock({ ...props }) {
  console.log("props======>>>>>>>>>", props);
  const [centeron, setCenteron] = useState(true);
  //  const [studentcon, setstudentcon] = useState(true);
  const [user, setUser] = useState();
  const [datafi, setDatafi] = useState({});
  const [blog, setBlog] = useState([]); 
  const [District, setDistrict] = useState([]);
  const [laston, setLastOn] = useState(false);
  const [Studentdata, setstudentdata] = useState([]);
  const [errr, setErrr] = useState(null);
  const [rollNumberInput, setRollNumberInput] = useState();
  const [dragArea, setdragArea] = useState(document.querySelector(".wrapper"));
  const [school, setSchool] = useState([]);
  const [team, setTeam] = useState([]);
  let history = useHistory();

  useEffect(() => {
    GetStudentList();
    //openChoiceBox();
    console.log("object.useEffect")
    setRollNumberInput(props.userRollNumber);
  }, [1000]);

  useEffect(() => {

    handleState();
    // handleBlog();
    handleDistrict();
  }, [1000]);


  const GetStudentList = () => {
    ExportApis.Postdatastudentlist(props.userDetail, 0, props.studenttype).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        console.log("all student data props.userRollNumber......", props.userRollNumber);
        if (Data.length > 0) {
          let ro = resp.data?.find((val) => val.rollNo == props.userRollNumber);
          console.log("single student data......", ro);
          setUser(ro);
          //openChoiceBox(ro.centerConfirm)
          // console.log("Student ro........===>", ro.centerConfirm);
          setstudentdata(Data);
        } else {
          alert("err");
        }
      }
    });
  };


  const handlesingleStudentdata = () => {
    console.log(">>>>>>>>>>>>>>>>new check", user); 
    ExportApis.StudentDetails(user?.uentityId).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        // console.log(">>>>>>>>>>>>>>>>", Data);

        team.forEach((element, index) => {
          //    console.log(element.schoolId);
          //  console.log("form value data show*****",element.id)

          ExportApis.StudentSchoolChoice(
            resp?.data.uentityId,
            resp?.data.studentId,
            index + 1,
            element.schoolId
          )
            .then((resp) => {
              console.log("data_ "+resp.ok);
              if (resp.ok) {
                index++;
                setCenteron(false);
                setLastOn(true);

                //console.log("form value data show*****", element.schoolId);
              } else {
                alert("err");
              }
            })
            .catch((err) => console.log(err));
        });
        setDatafi(Data);
      }
    });
  };

  const changeBlock = (id) => {
    // console.log('sunny',id)
    ExportApis.Block(id).then((resp) => {
      if (resp.ok) {

        let Data = resp.data;
        setBlog(resp.data);
      }
    });
  }


  // const handleBlog = () => {

  //   ExportApis.Block(48453).then((resp) => {
  //     if (resp.ok) {
  //       let Data = resp.data;
  //       setBlog(resp.data);
  //     }
  //   });
  // };

  // District Dropdown
  const handleDistrict = () => {

    ExportApis.GetDistrict().then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setDistrict(resp.data);
        console.log("district1111 ", resp.data)

      }
    });
  };

  const handleState = (id) => {
    //console.log("rollno ",props.userRollNumber)
    console.log("id ", id)
    console.log("roll ", id + " " + props.userRollNumber)
    ExportApis.LocationSchool(id, props.userRollNumber).then((resp) => {
      console.log("user scholl select",resp)
      if (resp.ok) {
        let Data = resp.data;
        setSchool(Data);
      console.log("school  List........===>", resp);
      }
    });
  };

  const choicesConfirm = () => {
    ExportApis.ChoicesConfirm(user.uentityId, 1)
      .then((resp) => {
        if (resp.ok) {

          history.push("/");

          // setVisible(false);
          //  console.log("form value data show*****");
        } else {
          alert("err");
        }
      })

      .catch((err) => console.log(err));
  };

  const [{ isOver }, addToTeamRef] = useDrop({
    accept: "player",

    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  // console.log(isOver);
  const [{ isOver: isPlayerOver }, removeFromTeamRef] = useDrop({
    accept: "team",
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  });

  const movePlayerToTeam = (item) => {
    if (team.length < 5) {
      GetStudentList();
      setSchool((prev) => prev.filter((_, i) => item.index !== i));
      setTeam((prev) => [...prev, item]);
    } else {
      setErrr("Please Choose min 5 Schools");
    }
  };
  const removePlayerFromTeam = (item) => {
    setTeam((prev) => prev.filter((_, i) => item.index !== i));
    setSchool((prev) => [...prev, item]);
  };

  const handleClick = (index) => {
    if (team.length < 5) {
      GetStudentList();
      setTeam([...team, school[index]]);
      let copy = [...school];
      copy.splice(index, 1);
      setSchool(copy);
      console.log("object of select School",copy)
    } else {
      setErrr(" Please Choose only 5 Schools");
    }
  };

  const handletClick = (index) => {
    setSchool([...school, team[index]]);
    let copyy = [...team];
    copyy.splice(index, 1);
    setTeam(copyy);
    setErrr(false);
  };

  const reorder = (team, startIndex, endIndex) => {
    const result = Array.from(team)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }


  const onEnd = (result) => {
    //console.log(result)
    setTeam(reorder(team, result.source.index, result.destination.index))
  }


  return (
    <div>

      {centeron ? (
        <>
          <br />
          <div className=" justify-content-md-center">
            <Heading p="2" align="center" color="#27AE60">
              Please Select Schools
            </Heading>
            <br />
            <br />

            <div className="col sm-11 justify-content-md-center">
              <center>

                <select
                  style={{
                    // borderRadius:"45%",
                    width: "60%",
                    paddingBlock: "10px",
                    color: "#27AE60",
                    border: "solid 2px #27AE60",
                  }}
                  // onChange={(e) => handleState(e.target.value)}

                  onChange={(e) => changeBlock(e.target.value)}
                // onChange={(e) => { changeState(e) }}

                >
                  <option selected>Open this select District</option>
                  {District?.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                    </>
                  ))}
                </select>
              </center>
            </div><br></br>
            <div className="col sm-11 justify-content-md-center">
              <center>

                <select
                  style={{
                    // borderRadius:"45%",
                    width: "60%",
                    paddingBlock: "10px",
                    color: "#27AE60",
                    border: "solid 2px #27AE60",
                  }}
                  onChange={(e) => handleState(e.target.value)}
                >
                  <option selected>Open this select Block</option>
                  {blog?.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                    </>
                  ))}
                </select>
              </center>
            </div>
          </div>
          <div>
            <br />
            <br />
            <br />
            <Container maxW="800px">
              <Heading p="2" align="center" color="#27AE60">
                Please Choose 5 School
              </Heading>

              <Flex justify="space-between" height="90vh" align="center">
                <Stack width="300px">
                  <Heading fontSize="3xl" color="yellow.800" textAlign="center">
                    School
                  </Heading>{" "}
                  <List
                    style={{ cursor: "pointer" }}
                    bgGradient={
                      isPlayerOver
                        ? "linear(to-b, yellow.300, yellow.500)"
                        : "linear(to-b, yellow.100, yellow.200)"
                    }
                    ref={removeFromTeamRef}
                    p="4"
                    minH="70vh"
                    boxShadow="xl"
                    borderRadius="md"
                  >
                    <div className="overflow-auto" style={{ height: "70vh" }}>
                      {school && school.length > 0 ? (
                        <>
                          {school?.map((p, i) => (
                            <Player
                              onClick={() => handleClick(i)}
                              item={p}
                              key={i}
                              playerType="player"
                              onDropPlayer={movePlayerToTeam}
                              index={i}
                            />
                          ))}
                        </>
                      ) : (
                        <center><h3><strong>Data Not Available</strong></h3></center> //<BeatLoader color="blue" loading />
                      )}
                    </div>
                  </List>
                </Stack>
                <Stack width="300px">
                  <Heading fontSize="3xl" color="teal.800" textAlign="center">
                    Selected
                  </Heading>

                  <DragDropContext onDragEnd={onEnd}>
                    <Droppable
                      droppableId="12333"
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="overflow-auto" style={{ height: "70vh" }}
                        >
                          <List

                            style={{ cursor: "pointer", animation: "350" }}
                            bgGradient={
                              isOver
                                ? "linear(to-b, teal.300, teal.500)"
                                : "linear(to-b, teal.100, teal.200)"
                            }
                            ref={addToTeamRef}
                            minH="75vh"
                            boxShadow="xl"
                            borderRadius="md"
                            p="4"   >
                            {team.map((p, i) => (

                              <Draggable
                                draggableId={`${p.id}`}
                                key={p.id}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Player

                                      onClick={() => handletClick(i)}
                                      item={p}
                                      key={i}
                                      index={i}
                                      playerType="team"
                                    // onDropPlayer={removePlayerFromTeam}
                                    />
                                  </div>
                                )}
                              </Draggable>

                            ))}
                            {provided.placeholder}
                          </List>

                        </div>
                      )}

                    </Droppable>


                  </DragDropContext>

                </Stack>
              </Flex>
              <br />
              {errr ? (
                <Heading align="center" color="red">
                  {errr}
                </Heading>
              ) : null}
              <br />
              <br />
            </Container>

            <button
              className="btn btn-success btn-block"

              onClick={() => {
                handlesingleStudentdata(user?.uentityId);
              }}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <div className="shadow p-2 mb-5 bg-white ">
          <br />
          <div className="row">
            <div className="col-7">
              <>
                {" "}
                <Heading fontSize="3xl" color="teal.800" textAlign="center">
                  Student Details
                </Heading>
                <br />
                <div className="row">
                    <div className="col-5 text-left">SCERT Registration No.</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.rollNo}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Name</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.studentName}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Father Name</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.fatherName}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">DOB</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.dob}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">EMAIL</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.emailId}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">class Name</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.className}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">MOBILE NUMBER</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.mobileNo}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Physical Disability</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {datafi.physicalDisability == 1 ? "Yes" : "No"}
                  </div>
                </div>
                {datafi.physicalDisability == 1 ? (
                  <>
                    <div className="row">
                      <div className="col-5 text-left">Disability Mobility</div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {datafi.disabilityMobility == 1 ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">
                        Disability Percentage
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {datafi.disabilityPercentage}%
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">Visibility Impaired</div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {datafi.visibilityImpaired == 1 ? "yes" : "No"}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="row">
                  <div className="col-5 text-left">Gender</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{datafi.genderName}</div>
                </div>
                {datafi.genderId == 2 ? (
                  <>
                    <div className="row">
                      <div className="col-5 text-left">
                        Female Marriage Status
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {datafi.marriageStatus == 1 ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">
                        Female Pregnant Status
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {datafi.isPregnant == 1 ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">
                        Delivery Expected Date
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {datafi.expectedDeliveryDate}
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            </div>
            <div className="col-5">
              <Heading fontSize="3xl" color="teal.800" textAlign="center">
                Priority
              </Heading>
              <div>
                {team.map((item, i) => (
                  <>
                    <br />

                    <div className="row">
                      <div className="col-2" style={{ fontWeight: "bold" }}>{i + 1}</div>
                      <div className="col-10">
                        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
                          {item.name}
                        </h6>
                      </div>
                    </div>

                  </>
                ))}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />

          <button
            className="btn btn-success btn-block"
            onClick={() => {
              choicesConfirm();
              setLastOn(false);
            }}
          >
            Confirm
          </button>
        </div>
      )}

    </div>
  );
}
const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
    userRollNumber: state.authentication.rollNumber,
  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(AppMapStateToProps, AppMapDispatchToProps)(SchoolBlock);













