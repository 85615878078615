import {  useState } from "react";
import { Button } from "react-bootstrap";
//import ExportApis from "src/Constants/Apis/ExportApis";
import bhiwani from "../../assets/bhivani.png";
function NewsD() {
    const [logindata, setLogindata] = useState(JSON.parse(localStorage.getItem("News")));
   
    return ( 
<>
<div className="container">
<div className="row">
          <div className="col-md-3"></div>
          <div className="col-sm-12 col-md-6" style={{ marginTop: "20px" }}>
            <img style={{ width: "100%" }} src={bhiwani} />
          </div>
        </div>

<br/>
<br/>
<div className="shadow p-2 mb-5 bg-white ">

        <div className="col-12" style={{ textAlign: "center" }}>
         
            <h5>News</h5> 
            </div> 
            <br/>
            <br/>
         
           <h6>{logindata?.tittle}</h6> 

          <br />
          <p>{logindata?.description} </p>

 
       <br/>
       <br/>

      <div style={{ textAlign: "center" }}>
      <Button href="/login" >
      login
            </Button>
      </div>
</div>
</div>
</>



     );
}

export default NewsD;