
import Dashboard from './Pages/Dashboard/Dashboard';
import StudentListForm from './Pages/Student/StudentListForm.js';
import SchoolBlock from './Pages/Student/School/SchoolBlock';
import CallForm from './Pages/Student/School/CallForm';
import Newstudent from './Pages/Student/Newstudent';
import Newstudentlist from './Pages/Student/Newstudentlist';
import { Finalchoice } from './Pages/Student/School/Finalchoice';
import StudentConditions from './Pages/Student/StudentConditions';
import NewSchoolInsert from './Pages/School/NewSchoolInsert'; //SchoolListDetail
import SchoolListDetail from './Pages/School/SchoolListDetail';
import UpdateSchool from './Pages/School/UpdateSchool';

const routes = [
  { path: '/', exact: true, name: 'Home' },

  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/StudentListForm', name: 'StudentListForm', component: StudentListForm },
  { path: '/CallForm', name: 'CallForm', component: CallForm },
  { path: '/SchoolBlock', name: 'SchoolBlock', component: SchoolBlock },
  { path: '/Newstudent', name: 'Newstudent', component: Newstudent },
  { path: '/Newstudentlist', name: 'Newstudentlist', component: Newstudentlist },
  { path: '/Finalchoice', name: 'Finalchoice', component: Finalchoice },
  { path: '/StudentConditions', name: 'StudentConditions', component: StudentConditions },
  { path: '/NewSchoolInsert', name: 'NewSchoolInsert', component: NewSchoolInsert },
  { path: '/SchoolListDetail', name: 'SchoolListDetail', component: SchoolListDetail },

  { path: '/UpdateSchool', name: 'UpdateSchool', component: UpdateSchool },
  


];

export default routes;
