import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // }
  },

  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Enroll New Student',
  //   to: '/Newstudent',
  //   icon: 'cil-puzzle',
  // },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Student List',
    route: '/StudentList',
    icon: 'cil-calculator',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'JBT-1',
        to: '/Newstudentlist',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'JBT-2',
        to: '/StudentList',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Re-Run (2021-2023) Student Mercy chance',
        to: '/ReRunStudent',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Add Student',
        to: '/Newstudent',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Student Details',
      //   to: '/StudentLetter',
      // },

    ]
  },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Complete Pending Choice ',
  //   route: '/Pending',
  //   icon: 'cil-calculator',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'JBT-1',
  //       to: '/Pending',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'JBT-2',
  //       to: '/Pending2',
  //     },

  //   ]
  // },
  // // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Complete Pending Choice ',
  //   to: '/Pending',
  //   icon: 'cil-puzzle',
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Sale',
  //   route: '/Sale',
  //   icon: 'cil-puzzle',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Sale Product',
  //       to: '/sale-product',
  //     },
  //     // {
  //     //   _tag: 'CSidebarNavItem',
  //     //   name: 'Sale History',
  //     //   to: '/sale-history',
  //     // },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Sale Reports',
  //       to: '/sale-reports',
  //     },
  //   ]
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Orders',
  //   route: '/Stock',
  //   icon: 'cil-puzzle',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'New Orders',
  //       to: '/Orders/New',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Accepted Orders',
  //       to: '/Orders/Accepted',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'All Orders',
  //       to: '/widgets',
  //     },
  //   ]
  // }

    {
       _tag: 'CSidebarNavDropdown',
     name: 'School',
     route: '/Stock',
     icon: 'cil-puzzle',
     _children: [
        {
         _tag: 'CSidebarNavItem',
         name: 'Add School',
        to: '/NewSchoolInsert',
      },
        {
         _tag: 'CSidebarNavItem',
       name: 'School List',
          to: '/SchoolListDetail',
       },
 
    ]
   }

]

export default _nav
