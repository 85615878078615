import { ListItem } from "@chakra-ui/react";
import React from "react";
import { useDrag } from "react-dnd";


const Player = ({ item, playerType,onClick, onDropPlayer, index }) => {

  const [{ isDragging }, dragRef] = useDrag({
    type: playerType,
    item: () => ({ ...item, index }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        onDropPlayer(item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });


  return (
  
    <ListItem
      p="2"
      onClick={onClick}
      borderRadius="md"
      boxShadow="md"
      mb="2"
      textAlign="center"
      ref={dragRef}
      bg={
        isDragging
          ? playerType === "player"
            ? "yellow.600"
            : "teal.400"
          : "white"
      }
      color={isDragging ? "white" : "black"}
    >
     {index+1} : {item.name}({item.locationName}),({item.schoolCode})
    </ListItem>
  
  );
};

export default Player;
