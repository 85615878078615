/* eslint-disable eqeqeq */
/* eslint-disable no-whitespace-before-property */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { CCard, CCardBody, CCardHeader, CBadge } from "@coreui/react";
import ExportApis from "src/Constants/Apis/ExportApis";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { PrintAllotmentLetter } from './School/PrintLetter';

import { BeatLoader } from "react-spinners"
import { getRollno } from "src/Constants/Redux/Actions/Authentication";
import Updatestudent from "./Updatestudent";
// const validationSchema = Yup.object().shape({
//   username: Yup.string().required("Please enter your Username!"),
//   password: Yup.string().required("Please enter your Password!"),
// });
class Newstudentlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // uentityId: '',
      currentUserId: '',
      StudentList: [],
      dataa: "",
      PhysicalDisabilityDocumentimg: "",
      Visiblt: false,
      // updateModal:false,
      datafi: null,
      datafi1: [],
      Center: [],
    };
  }

  componentDidMount() {
    //  console.log("singleData List........", this.state.datafi)
    //console.log("singleData List........", this.state.center)
    // let token = localStorage.getItem("user");
    // let decoded = jwt_decode(token);
    // let uEntityId=parseInt(decoded.unique_name)
    this.GetStudentList();
  }

  GetStudentList = () => {
    console.log("Stud userId........", this.props?.userDetail, 1, 1);
    ExportApis.GetReRunstudentlist(this.props?.userDetail, 1, 2).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          //console.log("Student List........", Data);
          this.setState({ StudentList: Data });
          this.setState({ updateModal1: false });


        }
      }
    );
  };
  HandleStudentForm = (data) => {
    //console.log(data);
    this.setState({ dataa: data });
    /// <Redirect to="/StudentListForm/"/>
    this.props.CurrentUserRollnum(data.rollNo)
    this.props.history.push("/StudentListForm/" + data.rollNo + "/" + 1);
  };
  HandleStudentcenter = (data) => {
    // console.log(data);
    this.setState({ dataa: data });
    this.props.CurrentUserRollnum(data.rollNo)
    this.props.history.push("/CallForm/" + data.rollNo + "/" + 1);
  };

  Handlefinalchoice = (data) => {
    ExportApis.ChoicesConfirm(data.uentityId, 1)
      .then((resp) => {
        if (resp.ok) {

          this.setState({ dataa: data });
          this.props.history.push("/");
          // setVisible(false);
          //  console.log("form value data show*****");
        } else {
          alert("err");
        }
      })

      .catch((err) => console.log(err));
  }

  handleSingleDetele = () => {
    this.setState({ secmodal: false });
    this.setState({ showsingledetele: true });
  };
  handleSingleDeteleModalHide = () => {
    this.setState({ secmodal: false });
    this.setState({ showsingledetele: false });
  };
  UpdeteSingleDeteleModalHide = () => {
    this.setState({ secmodal: true });
    this.setState({ showsingledetele: false });
  };

  handlesingleStudentdata = (uentityId) => {
    ExportApis.StudentDetails(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          this.setState({ datafi: resp.data });
          console.log("all data List........", this.state.datafi);
        }
      }
    );
    ExportApis.SchoolRequest(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          this.setState({ center: resp.data });
          this.setState({ Visiblt: true });
          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  };


  handleStudentupdate = (uentityId) => {
    this.setState({ datafi: null });

    ExportApis.StudentDetails(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          // this.setState({ uentityId: uentityId });
          // setSelectedId(uentityId);
          this.setState({ datafi: resp.data });
          console.log("singleData List........", Data);
        }
      })



    ExportApis.SchoolRequest(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          this.setState({ center: resp.data });
          this.setState({ updateModal1: true });
          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  }

  handleStudentchoicedata = (uentityId) => {
    ExportApis.StudentDetails(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          console.log('update choice data....', Data)
          this.setState({ datafi: resp.data });
          // console.log("singleData List........",Data );
        }
      })



    ExportApis.SchoolRequest(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          this.setState({ center: resp.data });
          this.setState({ updateModal: true });
          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  }

  DeleteStudentcenter = (uentityId) => {
    // console.log("uid",uentityId)
    ExportApis.Deletechoice(uentityId).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          //console.log("data",Data)
          // this.props.history.push("/");
          this.GetStudentList();

          this.setState({ updateModal: false });


          //  console.log("singleData List........", this.state.center);
        }
      }
    )
  };



  render() {
    const { StudentList, StockList } = this.state;
    const { center, Center } = this.state;
    // console.log(center)
    return (
      <>
        <CCard>
          <CCardHeader >Student List<strong> (Subject : JBT-1)</strong></CCardHeader>
          <CCardBody className="table-responsive">
            {StudentList && StudentList.length > 0 ? (
              <table className="table table-hover table-outline  mb-0 ">
                <thead >
                  <tr>
                    <th className="text-center">Sr.No</th>
                    <th className="text-center">Code</th>
                    <th className="text-center">Registration No</th>
                    <th className="text-center">Student Name</th>
                    <th className="text-center">Father Name</th>
                    <th className="text-center">Class</th>
                    <th className="text-center">Session</th>
                    <th className="text-center">Alloted School </th>
                    <th className="text-center">Update Choice</th>
                    {/* <th className="text-center">Update Profile</th> */}
                    <th className="text-center">Action</th>
                    <th className="text-center">Details</th>
                    <th className="text-center"> Allotment Letter</th>

                  </tr>
                </thead>
                <tbody>
                  {StudentList.map((data, i) => (
                    <tr key={data.uentityId}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{data.uentityId}</td>
                      <td className="text-center">{data.rollNo}</td>
                      <td className="text-center">{data.name}</td>
                      <td className="text-center">{data.fatherName}</td>
                      <td className="text-center">{data.className}</td>
                      <td className="text-center">{data.sessionName}</td>
                      <td className="text-center">{data.alloatedSchool}</td>

                      <td className="text-center">
                        <button
                          className="btn btn-warning text-white btn-sm"
                          onClick={() => {
                            this.handleStudentchoicedata(data.uentityId);
                          }}>
                          Update Choice
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-info text-white btn-sm"

                          onClick={() => {
                            this.handleStudentupdate(data.uentityId);
                          }}>
                          Update student

                        </button>
                      </td>
                      <td className="text-center">
                        {data.choicesConfirm == 1 ? (
                          <span className="badge badge-pill badge-dark">Completed</span>
                        ) : data.isConfirm == null || data.isConfirm == 0 ? (
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => this.HandleStudentForm(data)}
                          >
                            Set Profile
                          </button>
                        ) : data.centerConfirm == null || data.centerConfirm == 0 ? (
                          <button
                            className="btn btn-info btn-sm"
                            onClick={() => this.HandleStudentcenter(data)}
                          >
                            Set School
                          </button>
                        ) : <button
                          className="btn btn-info btn-sm"
                          onClick={() => this.Handlefinalchoice(data)}
                        >
                          Complete Choice
                        </button>}
                      </td>
                      <td className="text-center">
                        {data.choicesConfirm == 1 ? (
                          <button
                            onClick={() => {
                              this.handlesingleStudentdata(data.uentityId);
                            }}
                            className="btn btn-success btn-sm"
                          >
                            View Profile
                          </button>
                        ) : null}
                      </td>
                      <td className="text-center">

                        <button
                          onClick={() => PrintAllotmentLetter(data.uentityId)}
                          className="btn btn-info btn-sm"
                        >
                          Print Allotment
                        </button>

                        {/*  <button onClick={() => PrintAllotmentLetter(data.uentityId)}>PrintDetail</button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : <BeatLoader color="blue" loading />
              //  (
              //   <p>Loading...</p>
              // )
            }
          </CCardBody>
        </CCard>

        <Modal
          show={this.state.Visiblt}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="shadow p-2 mb-5 bg-white ">
              <center style={{ color: "#27AE60" }}>
                <h4 style={{ fontWeight: "bold" }}> Student Details</h4>
              </center>
            </div>
            {this.state.datafi ? (
              <>
                <div className="row">
                  <div className="col-5 text-left">Registration No</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.rollNo}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Name</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.studentName}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Father Name</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.fatherName}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Dob</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">{this.state.datafi.dob}</div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Email</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.emailId}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Mobile Number</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.mobileNo}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Physical Disability</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.physicalDisability == 1 ? "Yes" : "No"}
                  </div>
                </div>
                {this.state.datafi.physicalDisability == 1 ? (
                  <>
                    <div className="row">
                      <div className="col-5 text-left">Disability Mobility</div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {this.state.datafi.disabilityMobility == 1
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">
                        Disability Percentage
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {this.state.datafi.disabilityPercentage}%
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">Visibility Impaired</div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {this.state.datafi.visibilityImpaired == 1
                          ? "yes"
                          : "No"}
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="row">
                  <div className="col-5 text-left">Gender</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {" "}
                    {this.state.datafi.genderName}
                  </div>
                </div>
                <div className="row">
                  <div className="col-5 text-left">Class Name</div>
                  <div className="col-2 text-left">:</div>
                  <div className="col-5 text-left">
                    {this.state.datafi.className}
                  </div>
                </div>
                {this.state.datafi.genderId == 2 ? (
                  <>
                    <div className="row">
                      <div className="col-5 text-left">
                        Female Marriage Status
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {this.state.datafi.marriageStatus == 1 ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">
                        Female Pregnant Status
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {this.state.datafi.isPregnant == 1 ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5 text-left">
                        Delivery Expected Date
                      </div>
                      <div className="col-2 text-left">:</div>
                      <div className="col-5 text-left">
                        {this.state.datafi.expectedDeliveryDate}
                      </div>
                    </div>
                  </>
                ) : null}

                <br />
                <br />

                <div className="shadow p-2 mb-5 bg-white ">
                  <center style={{ color: "#27AE60" }}>
                    <h4 style={{ fontWeight: "bold" }}>Priority</h4>
                  </center>
                </div>
                <br />
                <br />

                {center?.map((item) => (

                  <>

                    <div className="row">
                      <div className="col-2 text-left" style={{ fontWeight: "bold" }}>{item.priority}</div>

                      <div className="col-10 ">{item.schoolName}</div>
                    </div>
                    <hr />
                  </>
                ))}
              </>
            ) :
              <BeatLoader loading />
              //  <p>Loding....</p>
            }
            <br />
            <button
              className="btn btn-primary btn-block"
              onClick={() => {
                this.setState({ Visiblt: false });
              }}
            >
              Cancel
            </button>
            <br />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.updateModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>

            {this.state.datafi ? (
              <>

                <div className="shadow p-2 mb-5 bg-white ">
                  <center style={{ color: "#27AE60" }}>
                    <h4 style={{ fontWeight: "bold" }}>Priority</h4>
                  </center>
                </div>
                <center> <div><h4 style={{ fontWeight: "bold", color: "red" }}>All Selected Choices Will Be Deleted  </h4></div></center>
                <br />
                <br />

                {center?.map((item) => (

                  <>

                    <div className="row">
                      <div className="col-2 text-left" style={{ fontWeight: "bold" }}>{item.priority}</div>

                      <div className="col-10 ">{item.schoolName}</div>
                    </div>
                    <hr />
                  </>
                ))}
              </>
            ) :
              <BeatLoader loading />

            }
            <br />
            <button
              className="btn btn-danger btn-block"
              onClick={() => {
                this.setState({ updateModal: false });
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-block"
              onClick={() => {
                this.DeleteStudentcenter(this.state.datafi.uentityId)
              }}
            >
              Confirm
            </button>
            <br />
          </Modal.Body>
        </Modal>

        {/* update student profile */}

        <Modal
          show={this.state.updateModal1}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>

            {this.state.datafi ? (
              <>

                <div className="shadow p-2 mb-5 bg-white ">
                  <center style={{ color: "#27AE60" }}>
                    <h4 style={{ fontWeight: "bold" }}>Update student profile</h4>
                  </center>
                </div>
                {/* <center> <div><h4 style={{ fontWeight: "bold", color: "red" }}>All Selected Choices Will Be Deleted  </h4></div></center> */}

                <Updatestudent dataupdate={this.state.datafi} GetStudentList={this.GetStudentList} />

              </>
            ) :
              <BeatLoader loading />

            }
            <button
              className="btn btn-danger btn-block"

              onClick={() => {
                this.setState({ updateModal1: false });
              }}
            >
              Cancel
            </button>
            <br />
            {/* <button
              className="btn btn-danger btn-block"
              onClick={() => {
                this.setState({ updateModal1: false });
              }}
            >
              Cancel
            </button> */}
            {/* <button
              className="btn btn-primary btn-block"
              onClick={() => {
                this.DeleteStudentcenter(this.state.datafi.uentityId)
              }}
            >
              Confirm
            </button> */}
            <br />
          </Modal.Body>
        </Modal>

      </>
    );
  }
}

const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
    userRollNumber: state.authentication.rollNumber,

  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    CurrentUserRollnum: (rollNumber) => dispatch(getRollno(rollNumber))
  };
};

export default connect(
  AppMapStateToProps,
  AppMapDispatchToProps
)(withRouter(Newstudentlist));
