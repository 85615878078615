import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
// import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from '../RootReducer/Index';


const Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default Store;
