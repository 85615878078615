/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
//import { Link } from "react-router-dom";
//import CIcon from "@coreui/icons-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { LoginFunction } from "src/Constants/Redux/Actions/Authentication";

import "../../assets/Loginstyle.css"
const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your Username!"),
  password: Yup.string().required("Please enter your Password!"),
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
   
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div  >

        <div className="limiter">
          <header>
            <div className="row">
              <div className="logo">
                <img src="https://school.bseh.net/assets/img/Logo_bhiwani.png" style={{ width: '100%' }} />
              </div>
              <div className="detail_content">
                <h3 className="mt-3">
                 SIP DED Education Haryana
                  {/* राष्ट्रीय साधन व योग्यता छात्रवृत्ति परीक्षा (NMMS) 2021-22 */}
                </h3>
                {/* <h6>एस. सी.ई.आर.. हरियाणा, गुरुग्राम </h6> */}
              </div>
              {/*<div class="right_header">
              <a href="https://school.bseh.net/" class="btn btn_register">Already Register</a>
          </div>*/}
            </div>
          </header>
           
          <div className="container-login100">
            <div className="row row_forcontent">
                <div className="col-md-6 bright">
                <div className="detail_content">
                </div>
                <div className="text_login">
                  <div className>
                    <div className="links_use">
                      <div className="wrap-login100">

                        <Formik
                          initialValues={{
                            username: "",
                            password: "",
                          }}
                          validationSchema={validationSchema}
                          onSubmit={(values, { resetForm }) => {
                            this.props.loginFunction(
                              values.username,
                              values.password,
                              this.props.history

                            );
                          }}

                        >
                          {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form className="login100-form validate-form" >
                              <span className="login100-form-title p-b-34 p-t-27">
                                Login
                              </span>
                              <h5 style={{ color: 'red', textAlign: 'center' }} />
                              <h3 style={{ color: 'red', textAlign: 'center' }} />
                              <div className="wrap-input100 validate-input" data-validate="Enter Code">
                                <input
                                  type="text"
                                  name="username"
                                  id="username"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.username}
                                  className="input100"
                                  placeholder="UserCode"
                                />
                                {errors.username && touched.username ? (
                                  <div style={{ color: "red" }}>{errors.username}</div>
                                ) : null}
                              </div>

                              <div className="wrap-input100 validate-input" data-validate="Enter password" id="pwddiv">

                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  className="input100"
                                  placeholder="Password"
                                />
                                {errors.password && touched.password ? (
                                  <div style={{ color: "red" }}>{errors.password}</div>
                                ) : null}

                              </div>
                              <div className="container-login100-otp-btn">
                              </div>
                              <div className="text-danger">{this.props.errorMsg}</div>
                              <div className="container-login100-otp-btn" id="loginButton">
                                <button className="login100-form-btn" type="submit">
                                  Login
                                </button>
                              </div>

                            </Form>
                          )}

                        </Formik>

                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="table table-responsive" style={{ marginTop: '-15px' }}>
                       
                    </div>
                    <div className="links_use">
                      
                    </div>
                  </div>
                </div>
              </div> 
              <div className="col-md-6 bright">
                <div className="login_section">
                  <div className="wrap-login100 selector">
                    <div className="login_section" style={{ textAlign: 'center' }}>
                      <h5> Help Number : 97286 66953</h5>
                      <h5> Mail Id : siphelpbseh@gmail.com</h5>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
         
        </div>
      </div>



    );
  }
}

const AppMapStateToProps = (state) => {
  return {
    errorMsg: state.authentication.msg,
  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    loginFunction: (username, password, history) =>
      dispatch(LoginFunction(username, password, history)),
  };
};

export default connect(AppMapStateToProps, AppMapDispatchToProps)(Login);
