// import ExportApis from "src/Constants/Apis/ExportApis";

// const PrintAllotmentLetter = (SelectedId) => {
//     console.log('sushil',SelectedId)

//     ExportApis.GetStudentDetails(SelectedId).then((resp) => {
//         if (resp.ok) {
//             let Data = resp.data;
//             printDiv(Data);
//         }
//     });
// };



// function printDiv(Studentdata) {


//     //Get the HTML of div
//     // var divElements = document.getElementById("tblPrintDiv").innerHTML;
//     //Get the HTML of whole page
//     var oldPage = document.body.innerHTML;
//     //Reset the page's HTML with div's HTML only
//     document.body.innerHTML =
//         `<html><head><title></title>
//       <!DOCTYPE html>
// <html>
// <body>  

// <br>
// <h1 style=" text-align: center; "color:black;"><b>Board of School Education Haryana,BHIWANI</b></h1><br><h2 style="color:black; text-align: center; "> Allotment Letter </h2>
// <br>

// <h2 style="color:black; text-align: left;">Certified that student teacher  <b> ${Studentdata.studentName} </b>
// Board Roll No.<b>${Studentdata.rollNo}</b>  of </h2> <br><h2 style="color:black;text-align: left;">
// <b> ${Studentdata.collegeName} </b>college of education  </h2><br> <h2 style="color:black;text-align: left;"> <b> ${Studentdata.collegeDistrict} </b>   is allotted  <b> ${Studentdata.alloatedSchool}   </b>
//   Block of <b> ${Studentdata.alloatedSchoolBlock}</b></h2><br> <h2 style="color:black;text-align: left;"> District <b>${Studentdata.alloatedSchoolDistrict}</b> to complete school internship  programme of  </h2> <br><h2 style="color:black; text-align: left;"> 7 weeks/4 weeks starting from 05-04-2023. </h2>


//  <br><h2 style="color:black;"><b> General Instructions:</b></h2>
//  <h3 style="color:black;"><b>1.	You are directed to attend the orientation programme and assessment programme to be held on first 2 days (05-04-2023 & 06-04-2023) and last two days (4 weeks 01-05-2023 & 02-05-2023 or 7 weeks 25-05-2023 & 26-05-2023) at DIET in district of your college.  </b> </h3>
//  <h3 style="color:black;"><b> 2.	You have to submit this allotment letter on the first day of orientation programme at DIET and you will collect this on second day making sure the stamp and signature of DIET Principal.</b>
//  </h3>
//  <h3 style="color:black;"> <b>3.	You have to prepare your files during SIP programme in schools.
//  </h3>
//  </b><h3 style="color:black;"><b> 4	90% of attendance is compulsory to complete school internship programme. 
//  </b></h3>
//  <h3 style="color:black;"> <b>5.	Attendance will be marked online on-Board website by school head also offline on register in school.
//  </b></h3>
//  <h3 style="color:black;"> <b>6.  You have to attend school in your college uniform and Id card issued by your teaching institute.
//  <b></h3>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <h2 style="color:black;text-align: right;"> Signature college Principal
//  With Stamp
//  </h2>
//  <br>
//  <br>
//  <br>
//  <br>
//  <h2 style="color:black;text-align:left;"> Stamp and Sign of DIET Principal 
//  (First Two days)

//  </h2>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <br>
//  <h2 style="color:black;text-align:left;"> Stamp and Sign of DIET Principal 
//  (Last Two days)

//  </h2>


// </body>
// </html>


//       `+
//         "</body>";
//     //Print Page
//     window.print();
//     //Restore orignal HTML
//     document.body.innerHTML = oldPage;
//     window.location.reload(false);
// }



// export {PrintAllotmentLetter}



import ExportApis from "src/Constants/Apis/ExportApis";

const PrintAllotmentLetter = (SelectedId) => {
    console.log('sushil', SelectedId)

    ExportApis.GetStudentDetails(SelectedId).then((resp) => {
        if (resp.ok) {
            let Data = resp.data;
            printDiv(Data);
        }
    });
};



function printDiv(Studentdata) {


    //Get the HTML of div
    // var divElements = document.getElementById("tblPrintDiv").innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;
    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
        `<html><head><title></title>
      <!DOCTYPE html>
<html>
<body>  

<br>
<h1 style=" text-align: center; "color:black;"><b>Board of School Education Haryana,Bhiwani</b></h1><br><h2 style="color:black; text-align: center; "> School Allotment Letter for SIP</h2>
<br>

<h2 style="color:black; text-align: left;">Certified that student teacher  <b> ${Studentdata.studentName} </b>
Board Roll No.<b>${Studentdata.rollNo}</b>  of </h2> <br><h2 style="color:black;text-align: left;">
<b> ${Studentdata.collegeName} </b>college of education  </h2><br> <h2 style="color:black;text-align: left;"> <b> ${Studentdata.collegeDistrict} </b>   is allotted  <b> ${Studentdata.alloatedSchool}   </b>
  Block of <b> ${Studentdata.alloatedSchoolBlock}</b></h2><br> <h2 style="color:black;text-align: left;"> District <b>${Studentdata.alloatedSchoolDistrict}</b> to complete school internship  programme of  </h2> <br><h2 style="color:black; text-align: left;"> 04 Weeks/07 Weeks starting from 01.04.2024. </h2>


 <br><h2 style="color:black;"><b> General Instructions:</b></h2>
 <h3 style="color:black;"><b>1.	1.	You are directed to attend the orientation programme to be held on first 2 days (01-04-2024 & 02-04-2024) and assessment programme to be held on last two days (4 weeks (29-04-2024 & 30-04-2024 ) and 7weeks (17-05-2024 & 18-05-2024)) at DIET in the district of your college.  </b> </h3>
 <h3 style="color:black;"><b> 2.	You have to submit this allotment letter on the first day of orientation programme at DIET and you will collect this on second day making sure the stamp and signature of DIET Principal.</b>
 </h3>
 <h3 style="color:black;"> <b>3.	You have to prepare your files during SIP programme in schools.
 </h3>
 </b><h3 style="color:black;"><b>4.    90% of attendance is compulsory to complete school internship programme. 
 </b></h3>
 <h3 style="color:black;"> <b>5.	Attendance will be marked online on-Board website by school head also offline on register in school.
 </b></h3>
 <h3 style="color:black;"> <b>6.  You have to attend school in your college uniform and Id card issued by your teaching institute.
 <b></h3>
 <br>
 <br>
 <br> 
 <h2 style="color:black;text-align: right;"> Signature college Principal
 With Stamp
 </h2>
 <br>
 <br>
 <br> 
 <h2 style="color:black;text-align:left;"> Stamp and Sign of DIET Principal 
 (First Two days)
 </h2>
 <br>
 <br> 
 <br> 
 <h2 style="color:black;text-align:left;"> Stamp and Sign of DIET Principal 
 (Last Two days)
 
 </h2>


</body>
</html>


      `+
        "</body>";
    //Print Page
    window.print();
    //Restore orignal HTML
    document.body.innerHTML = oldPage;
    window.location.reload(false);
}



export { PrintAllotmentLetter }