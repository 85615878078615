import React, { Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";
import Dashboard from "src/Pages/Dashboard/Dashboard";
import StudentList from "src/Pages/Student/StudentList";
// import StudentListForm from "src/Pages/Student/StudentListForm.js";
import SchoolBlock from "src/Pages/Student/School/SchoolBlock";
// import CallForm from "src/Pages/Student/School/CallForm";
import ProtectedRoutes from "src/Constants/ProtectedRoutes";
import Newstudent from "src/Pages/Student/Newstudent";
import Newstudentlist from "src/Pages/Student/Newstudentlist";
import { Finalchoice } from "src/Pages/Student/School/Finalchoice";
import Pending from "src/Pages/Student/School/Pending";
import Pending2 from "src/Pages/Student/School/Pending2";
import ReRunStudent from "src/Pages/Student/ReRunStudent";
import StudentConditions from "src/Pages/Student/StudentConditions";
import StudentLetter from "src/Pages/Student/StudentLetter";
import NewSchoolInsert from "src/Pages/School/NewSchoolInsert";
import SchoolListDetail from "src/Pages/School/SchoolListDetail";
import UpdateSchool from "src/Pages/School/UpdateSchool";
const StudentListForm = React.lazy(() => import("src/Pages/Student/StudentListForm"));
const CallForm = React.lazy(() => import("src/Pages/Student/School/CallForm"));





const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            <ProtectedRoutes path="/dashboard" name="Dashboard" component={Dashboard} />
            
            <ProtectedRoutes
              path="/UpdateSchool/:id"
              name="UpdateSchool"
              component={UpdateSchool}
            />
            <ProtectedRoutes
              path="/SchoolListDetail"
              name="NewSchool"
              component={SchoolListDetail}
            />
            <ProtectedRoutes
              path="/NewSchoolInsert"
              name="NewSchool"
              component={NewSchoolInsert}
            />
            <ProtectedRoutes
              path="/StudentList"
              name="StudentList"
              component={StudentList}
            />
            <ProtectedRoutes
              path="/[rollono]/:rollno"
              name="SchoolBlock"
              component={SchoolBlock}
            />

              <ProtectedRoutes
              exact
              path="/Newstudent"
              name="Newstudent"
              component={Newstudent}
            />  
            <ProtectedRoutes
              exact
              path="/Newstudentlist"
              name="Newstudentlist"
              component={Newstudentlist}
            />
            <ProtectedRoutes
              exact
              path="/ReRunStudent"
              name="ReRunStudent"
              component={ReRunStudent}
            />
            <ProtectedRoutes
              exact
              path="/StudentLetter"
              name="StudentLetter"
              component={StudentLetter}
            />
            <ProtectedRoutes
              exact
              path="/StudentConditions"
              name="StudentConditions"
              component={StudentConditions}
            />

            {/* <ProtectedRoutes
              exact
              path="/Pending"
              name="Pending"
              component={Pending}
            />
              <ProtectedRoutes
              exact
              path="/Pending2"
              name="Pending2"
              component={Pending2}
            /> */}
            <ProtectedRoutes
              exact
              path="/Finalchoice"
              name="Finalchoice"
              component={Finalchoice}
            />
            <Route
              exact
              path="/StudentListForm/:rollNo/:studenttype"
              name="StudentListForm"
              render={(props) => <StudentListForm {...props} />}
            />

            <ProtectedRoutes
              path="/CallForm/:rollNo/:studenttype"
              name="StudentListForm"
              component={CallForm}
            />

            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default withRouter(TheContent);
