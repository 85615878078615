import React, { useEffect, useState, useRef } from 'react';
import { CCard, CCardBody, CCardHeader, CBadge } from "@coreui/react";
import { Container, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import ExportApis from "src/Constants/Apis/ExportApis";
import { BeatLoader } from "react-spinners"
import { getRollno } from "src/Constants/Redux/Actions/Authentication";
import { ToastContainer, toast } from 'react-toastify';

//import { Form, Formik } from "formik";
import * as Yup from 'yup';
const validationSchema = Yup.object().shape({
  studentName: Yup.string().required('Name is required!'),


});


function StudentLetter({ ...props }) {
  const [StudentList, setStudentList] = useState();

  //const notifyupdate = () => toast.success('Sucessfully Updated!')



  const GetStudentList = () => {

    console.log("Stud userId........ GetStudentList", props.userDetail);
    ExportApis.GetReRunstudentlist(props.userDetail, 3, 0).then(
      (resp) => {
        if (resp.ok) {
          let Data = resp.data;
          //   console.log("Student List jbt1........", Data);
          setStudentList(Data)
          // console.log("CHEK",props.userDetail)
        }
      }
    );
  };


    // Student Details
    const StudentDetails = (SelectedId) => {
      console.log('sushil',SelectedId)

      ExportApis.GetStudentDetails(SelectedId).then((resp) => {
          if (resp.ok) {
              let Data = resp.data;
              printDiv(Data);
          }
      });
  };





  useEffect(() => {
    GetStudentList()

  }, []);


  function printDiv(Studentdata) {


    //Get the HTML of div
    // var divElements = document.getElementById("tblPrintDiv").innerHTML;
    //Get the HTML of whole page
    var oldPage = document.body.innerHTML;
    //Reset the page's HTML with div's HTML only
    document.body.innerHTML =
        `<html><head><title></title>
      <!DOCTYPE html>
<html>
<body> <br>

<br>
<h1 style=" text-align: center; "color:black;">Board Of School Education Haryana,BHIWANI</h1><br><h2 style="color:black; text-align: center; "> Allotement Letter </h2>
<br>

<h2 style="color:black; text-align: left;">Certified that student teacher..<u> ${Studentdata.studentName}</u>
Board Roll No.<u>${Studentdata.rollNo} </u>Of</h2> <br><h2 style="color:black;text-align: left;">
 ${Studentdata.collegeName} college of education District <u>Fatehabad</u>  </h2><br> <h2 style="color:black;text-align: left;">is allotted  <u> ${Studentdata.alloatedSchool} </u> 
 <u>  ${Studentdata.alloatedSchool}  </u>(Adress).to complete school </h2> <br><h2 style="color:black; text-align: left;">internship programme of 7 weeks/4weeks starting from 12-10-2022. </h2>


 <br><h2 style="color:black;"> General Instructions:</h2>
 <h3 style="color:black;"> 1.	You are directed to attend the orientation programme to be held on first 2 days (12-10-2022 & 14-10-2022) and last two days (28-29 November 2022) at DIET in district of your college. If you are absent in this first two days orientation programme, then you will not be permitted to start you SIP in allotted school.  </h3>
 <h3 style="color:black;"> 2.	You have to submit this allotment letter on the first day of orientation programme at DIET and you will collect this on second day making sure the stamp and signature of DIET Principal. Failing this you cannot join for SIP in school.
 </h3>
 <h3 style="color:black;"> 3.	You have to prepare your files during SIP programme in schools.
 </h3>
 <h3 style="color:black;"> 4	90% of attendance is compulsory to complete school internship programme. 
 </h3>
 <h3 style="color:black;"> 5.	Attendance will be marked online on-Board website by school head also offline on register in school.
 </h3>
 <br>
 <br>
 <br>
 <br>
 <br>
 <br>
 <br>
 <br>
 <h2 style="color:black;text-align: right;"> Signature college Principal
 With stamp
 </h2>
 <br>
 <br>
 <h2 style="color:black;text-align:left;"> Stam and Sign of DIET Principal 
 (First Two days)
 
 </h2>
 <br>
 <br>
 <br>
 <br>
 <br>
 <br>
 <br>
 <br>
 <h2 style="color:black;text-align:left;"> Stam and Sign of DIET Principal 
 (Last Two days)
 
 </h2>


</body>
</html>


      `+
        "</body>";
    //Print Page
    window.print();
    //Restore orignal HTML
    document.body.innerHTML = oldPage;
    window.location.reload(false);
}










  return (
    <>
      <CCard>
        <CCardHeader >Student List<strong> (Subject : Re-Run)</strong></CCardHeader>
        <CCardBody className="table-responsive">

          {StudentList && StudentList.length > 0 ? (
            <table className="table table-hover table-outline  mb-0 ">
              <thead >
                <tr>
                  <th className="text-center">Sr.No</th>
                  <th className="text-center">Code</th>
                  <th className="text-center">Roll No</th>
                  <th className="text-center">Student Name</th>
                  <th className="text-center">Father Name</th>
                  <th className="text-center">Class</th>
                  <th className="text-center">Session</th>
                  <th className="text-center">Alloted School </th>
              
                  <th className="text-center">Print Details</th>

                </tr>
              </thead>

              <tbody>
                {StudentList.map((data, i) => (
                  <tr key={data.uentityId}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{data.uentityId}</td>
                    <td className="text-center">{data.rollNo}</td>
                    <td className="text-center">{data.name}</td>
                    <td className="text-center">{data.fatherName}</td>
                    <td className="text-center">{data.className}</td>
                    <td className="text-center">{data.sessionName}</td>
                    <td className="text-center">{data.alloatedSchool}</td>
                    <td className="text-center">  <button className="btn btn-info  mr-4" style={{ float: 'right', }}
                      onClick={() => StudentDetails(data.uentityId)}
                    >Print Details</button></td>







                  </tr>
                ))}
              </tbody>
            </table>
          ) : <BeatLoader color="blue" loading />
          }
        </CCardBody>
      </CCard>









      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}
const AppMapStateToProps = (state) => {
  return {
    userDetail: state.authentication.currentUser,
    userRollNumber: state.authentication.rollNumber,

  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    CurrentUserRollnum: (rollNumber) => dispatch(getRollno(rollNumber))
  };
};


export default connect(AppMapStateToProps, AppMapDispatchToProps)(StudentLetter);

